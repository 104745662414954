// import React, { Children, Component } from 'react';
import React, { Component } from 'react';
import InputMask from 'react-input-mask';
import { withTranslation } from 'react-i18next';
import { toast } from "react-toastify";
// import Loader from '../elements/Loader';
import {DOJ, SalaryPeriod} from "./DatePicker";
import SalaryFilter from "./SalaryReportFilter"
import SalaryReportTable from "./SalaryReportTable"
import { PaymentService } from '../../service';
import SingleExcelExport from '../elements/SingleExcelExport';
import FileUploader from "./FileUpload";
import { AMOUNT_FORMAT_TYPE, DATE_FORMAT_DMY } from "../../config/constant"
import Select from "react-select";
import dateFormat from "dateformat"
import { numberFormatWithDots, numberFormatWithLocale } from '../common/Helper';
class SalaryReport extends Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleSalaryPeriod = this.handleSalaryPeriod.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.state = { npwp_number :'', joining_date:'',basic_salary:'', transportation_allowance:'', communication_allowance:'',total_income:'',bro_communication_allowance : '',professional_allowance : '' , incentive : '', bonus: '', salary_deduction: '', gross_income: '', take_home_pay: '', salary_period: '',
            errors : {},
            salaryReportList: [],
            pagination: {},
            loading: false,
            Impportdatapopup: false,
            AddSalaryPaymentpopup:false,
            toggleFilterOption:'display',
            filterData:{},
            isFileUploaded:false,
            showDownloadModal:false,
            amountFormatType:'',
            resetFileUpload: ''
        };
    }

    componentDidMount = () => {
        this.getSalaryReportList({page : 1});    
    }

    componentDidUpdate = (prev_prop , prev_state) =>{
        if(prev_state.basic_salary !== this.state.basic_salary || prev_state.transportation_allowance !== this.state.transportation_allowance || prev_state.communication_allowance !== this.state.communication_allowance){
            let {basic_salary , transportation_allowance , communication_allowance} = this.state
            
            this.setState({
                total_income: +basic_salary + +transportation_allowance + +communication_allowance
            })
        }
    }


    handleChange(event){
        let income = ''
        
        if (event.target.name) {
            if(event.target.value === 0){
                income = (event.target.value.replace(/(0+(?=0))/gm, ``))
            }
            else(
                income = (event.target.value.replace(/\D/g, ''))
            )
          }
            this.setState({
                [event.target.name] : income,
              })


            income =Number(event.target.value.replace(/\D/g, ''))
            if(event.target.name === "npwp_number"){
                this.setState({
                    npwp_number : event.target.value
                  })
            }
            if(event.target.name === "basic_salary"){
                this.setState({
                    basic_salary : income
                  })
            }if(event.target.name === "gross_income"){
                this.setState({
                    gross_income : income
                  })
            }if(event.target.name === "take_home_pay"){
                this.setState({
                    take_home_pay : income
                  })
            }
           
           
    }

    handleDateChange(date) {
        if (date) {
        this.setState({
            joining_date : date
        }) 
         }
         
        else{
        this.setState({
            joining_date : ''
        }) 
    }
    }

    handleSalaryPeriod(period) {
        this.setState({
            salary_period : period
        })
    }

    validateData = () => {
        // const {  errors ,npwp_number , joining_date,basic_salary, transportation_allowance, communication_allowance,total_income,bro_communication_allowance ,professional_allowance  , incentive , bonus, salary_deduction, gross_income, take_home_pay, salary_period} = this.state
        const {  errors ,npwp_number , joining_date,basic_salary, transportation_allowance, communication_allowance,bro_communication_allowance ,professional_allowance  , incentive , bonus, salary_deduction, gross_income, take_home_pay, salary_period} = this.state
        if (!joining_date) errors.joining_date = "joining_date is mandatory"; else errors.joining_date = ''
        if (!salary_period) errors.salary_period = "salary_period  is mandatory"; else errors.salary_period = ''
        if (!basic_salary || (isNaN(basic_salary)) || basic_salary === 0) { errors.basic_salary = 'Basic salary should be numeric value and not negative'; } else errors.basic_salary = ''
        if ((isNaN(transportation_allowance))) { errors.transportation_allowance = 'TransportationAllowance should be numeric value and not negative'; } else errors.transportation_allowance = ''
        if ((isNaN(communication_allowance) )) { errors.communication_allowance = 'CommunicationAllowance should be numeric value and not negative'; }else errors.communication_allowance = ''
        if ((isNaN(bro_communication_allowance) )) { errors.bro_communication_allowance = 'BROCommunicationAllowance should be numeric value and not negative'; }else errors.bro_communication_allowance = ''
        if ((isNaN(professional_allowance) )) { errors.professional_allowance = 'ProfessionalAllowance should be numeric value and not negative'; }else errors.professional_allowance = ''
        if ((isNaN(incentive))) { errors.incentive = 'Incentive should be numeric value and not negative'; }else errors.incentive = ''
        if ((isNaN(bonus))) { errors.bonus = 'Bonus should be numeric value and not negative'; }else errors.bonus = ''
        if ((isNaN(salary_deduction))) { errors.salary_deduction = 'SalaryDeduction should be numeric value and not negative'; }else errors.salary_deduction = ''
        if (!gross_income || (isNaN(gross_income))) { errors.gross_income = 'GrossIncome should be numeric value and not negative'; }else errors.gross_income = ''
        if (!take_home_pay || (isNaN(take_home_pay))) { errors.take_home_pay = 'TakeHomePay should be numeric value and not negative'; }else errors.take_home_pay = ''
        if (!npwp_number || !(/^\d\d\.\d\d\d\.\d\d\d\.\d-\d\d\d\.\d\d\d$/i.test(npwp_number))) errors.npwp_number = 'npwp number format is not correct'; else errors.npwp_number = ''
                               
        if (errors.npwp_number || errors.joining_date || errors.basic_salary || errors.salary_deduction || errors.incentive || errors.bonus || errors.gross_income ||  errors.professional_allowance || errors.transportation_allowance || errors.communication_allowance || errors.bro_communication_allowance  || errors.take_home_pay || errors.salary_period) {
            this.setState({errors : {...errors} })
            return false;
        }
        return true;
    };

    handleSubmit = async () => {

            if(this.validateData()){
                this.setState({ loading: true });
                const {  npwp_number , joining_date,basic_salary, transportation_allowance, communication_allowance,bro_communication_allowance ,professional_allowance  , incentive , bonus, salary_deduction, gross_income, take_home_pay, salary_period} = this.state
                
                let data = { npwp_number , joining_date,basic_salary, transportation_allowance, communication_allowance,bro_communication_allowance ,professional_allowance  , incentive , bonus, salary_deduction, gross_income, take_home_pay, salary_period}
                
                const convert = (salary_period) => {
                    var date = new Date(salary_period)
                    let mnth = ("0" + (date.getMonth() + 1)).slice(-2);
                    return [mnth , date.getFullYear() ].join("/");
                  }
                data.salary_period = convert (salary_period)

                await PaymentService.addSalaryPayment(data)
                    .then(response => {
                        if (response.status === 200 && response.data.status === 200) {
                            toast.success(response.data && response.data.message);
                            this.hideModalAddSalaryPaymentpopup()
                            this.setState({
                                 npwp_number :'', joining_date:'',basic_salary:'', transportation_allowance:'', communication_allowance:'',total_income:'',bro_communication_allowance : '',professional_allowance : '' , incentive : '', bonus: '', salary_deduction: '', gross_income: '', take_home_pay: '', salary_period: ''
                            })
                        }
                    }).catch(err => {})
                    .finally(() => {  
                        this.setState({ loading: false , filterData : {page:1}  })    
                        this.getSalaryReportList(this.state.filterData);
                    })
            }
        };  
    
    toggleFilterHandler = (e) => {
        if(this.state.toggleFilterOption === "none")
            this.setState({
                toggleFilterOption : 'display'
            })
        else{
            this.setState({
                toggleFilterOption : 'none'
            })
            }    
        }

    
    getSalaryReportList = (value) => {
        let params = value
        if(this.state.filterData !== {}){
            this.setState({
                filterData: params,
            })
        }
        this.setState({ loading: true , salaryReportList: []});
        PaymentService.getSalaryReportList(params)
            .then(resp => {
                if (resp && resp.status === 200 && resp.data.status === 200) {
                    let { data ,pagination} = resp.data;
                    this.setState({ salaryReportList: data});
                    this.setState({ pagination: pagination});
                }
            })
            .catch(err => {})
            .finally(() => { 
                this.setState({ loading: false });
            })

    }

    exportData = () => {
        this.setState({ loading: true });
        let {amountFormatType, filterData} = this.state;
        PaymentService.getSalaryReportList(filterData)
        .then(response => {
            if (response.status === 200 && response.data && response.data.status === 200) {
                let { data } = response.data;
               
                if (data.length) {
                    let headerTitle = {
                        NPWP: this.props.t("SALARY_REPORT.NPWP"),
                        JOIN_DATE:  this.props.t("SALARY_REPORT.JOIN_DATE"),
                        BASIC_SALARY:  this.props.t("SALARY_REPORT.BASIC_SALARY"),
                        TRANSPORTATION_ALLOWANCE: this.props.t("SALARY_REPORT.TRANSPORTATION_ALLOWANCE"),
                        COMMUNICATION_ALLOWANCE:  this.props.t("SALARY_REPORT.COMMUNICATION_ALLOWANCE"),
                        TOTAL_INCOME : this.props.t("SALARY_REPORT.TOTAL_INCOME"),
                        BRO_COMMUNICATION_ALLOWANCE:  this.props.t("SALARY_REPORT.BRO_COMMUNICATION_ALLOWANCE"),
                        PROFESSIONAL_ALLOWANCE:  this.props.t("SALARY_REPORT.PROFESSIONAL_ALLOWANCE"),
                        INCENTIVE: this.props.t("SALARY_REPORT.INCENTIVE"),
                        BONUS:  this.props.t("SALARY_REPORT.BONUS"),
                        SALARY_DEDUCTION: this.props.t("SALARY_REPORT.SALARY_DEDUCTION"),
                        GROSS_INCOME: this.props.t("SALARY_REPORT.GROSS_INCOME"),
                        TAKE_HOME_PAY:  this.props.t("SALARY_REPORT.TAKE_HOME_PAY"),
                        SALARY_PERIOD: this.props.t("SALARY_REPORT.SALARY_PERIOD"),
                    }
                    let dataArr = [];
                    for (let ck = 0; ck < data.length; ck++) {
                        let element = data[ck];
                        let records = {};
                        let total_income = +element.basic_salary + +element.transportation_allowance + +element.communication_allowance;
                        records[headerTitle.NPWP] = element.npwp_number;
                        records[headerTitle.JOIN_DATE] = element.joining_date ? dateFormat(element.joining_date,DATE_FORMAT_DMY):'';
                        records[headerTitle.BASIC_SALARY] = element.basic_salary ? numberFormatWithLocale(element.basic_salary, amountFormatType) : 0;//element.basic_salary;
                        records[headerTitle.TRANSPORTATION_ALLOWANCE] = element.transportation_allowance ? numberFormatWithLocale(element.transportation_allowance, amountFormatType) : 0;//element.transportation_allowance;
                        records[headerTitle.COMMUNICATION_ALLOWANCE] = element.communication_allowance ? numberFormatWithLocale(element.communication_allowance, amountFormatType) : 0;//element.communication_allowance;
                        records[headerTitle.TOTAL_INCOME] = total_income ? numberFormatWithLocale(total_income, amountFormatType) : 0;//element.basic_salary + element.transportation_allowance +element.communication_allowance;
                        records[headerTitle.BRO_COMMUNICATION_ALLOWANCE] = element.bro_communication_allowance ? numberFormatWithLocale(element.bro_communication_allowance, amountFormatType) : 0;//element.bro_communication_allowance;
                        records[headerTitle.PROFESSIONAL_ALLOWANCE] = element.professional_allowance ? numberFormatWithLocale(element.professional_allowance, amountFormatType) : 0;//element.professional_allowance;
                        records[headerTitle.INCENTIVE] = element.incentive ? numberFormatWithLocale(element.incentive, amountFormatType) : 0;//element.incentive;
                        records[headerTitle.BONUS] = element.bonus ? numberFormatWithLocale(element.bonus, amountFormatType) : 0;//element.bonus;
                        records[headerTitle.SALARY_DEDUCTION] = element.salary_deduction ? numberFormatWithLocale(element.salary_deduction, amountFormatType) : 0;//element.salary_deduction;
                        records[headerTitle.GROSS_INCOME] = element.gross_income ? numberFormatWithLocale(element.gross_income, amountFormatType) : 0;//element.gross_income;
                        records[headerTitle.TAKE_HOME_PAY] = element.take_home_pay ? numberFormatWithLocale(element.take_home_pay, amountFormatType) : 0;//element.take_home_pay;
                        records[headerTitle.SALARY_PERIOD] = element.salary_period;
                        // if (maintab != 'pending') financierRecords[headerTitle.TYPE] = element.type;
                        dataArr.push(records);
                    }  

                    SingleExcelExport(dataArr , "Salary Data Report");
                    this.setState({ loading: false  ,showDownloadModal: false,amountFormatType:'' });
                }
                else {
                    toast.error('Record not found')
                }
                //setExportDataLoading(false);
            }
        });
    }

    showModalImpportdatapopup = () => {
        this.setState({ Impportdatapopup: true ,  resetFileUpload: new Date() });
        document.body.classList.add("overflow-hidden");
    }

    hideModalImpportdatapopup = () => {
        this.setState({ Impportdatapopup: false });
        document.body.classList.remove("overflow-hidden");
        this.setState({ loading: false , filterData : {page:1} , resetFileUpload: '' })    
        this.getSalaryReportList(this.state.filterData);
    }

    showModalAddSalaryPaymentpopup = () => {
        this.setState({ AddSalaryPaymentpopup: true });
        document.body.classList.add("overflow-hidden");
    }

    hideModalAddSalaryPaymentpopup = () => {
        this.setState({
            AddSalaryPaymentpopup: false , npwp_number :'', joining_date:'',basic_salary:'', transportation_allowance:'', communication_allowance:'',total_income:'',bro_communication_allowance : '',professional_allowance : '' , incentive : '', bonus: '', salary_deduction: '', gross_income: '', take_home_pay: '', salary_period: ''
        })
        document.body.classList.remove("overflow-hidden");
    }

    hideDownloadModal = () => {
        this.setState({ showDownloadModal: false,amountFormatType:'' });
        document.body.classList.remove("overflow-hidden");
    }

    render() {

        const fileUploadCallback = ({ status = false }) => {
            this.setState({
                isFileUploaded:status
            })
        }

        let { filterData ,salaryReportList, pagination ,loading,showDownloadModal,errors,amountFormatType } = this.state;
        return (
            <div className="salary-report-outer">


                <div className="container">
                    <div class="top-heading">
                        <h1>Salary Data </h1>
                        <div className='right-btn'>
                            <button class="btn-line" onClick={this.showModalAddSalaryPaymentpopup}><i className='ic-add'></i> Add Salary</button>
                            <button class="btn-line" onClick={this.showModalImpportdatapopup}> Bulk Upload</button>
                            <div class="fam-filter-toggle">
                            <li onClick={(e) => this.toggleFilterHandler(e)} className="">
                                {Object.keys(filterData).length > 1 && this.state.toggleFilterOption === "none" ? <span className="fam-filter-applied"></span> : null}
                                <span> <i className="ic-filter">
                                    <i className="path1"></i>
                                    <i className="path2"></i>
                                    <i className="path3"></i>
                                    <i className="path4"></i>
                                    <i className="path5"></i>
                                    <i className="path6"></i>
                                </i>
                                </span>
                               
                            </li>
                            </div>
                        </div>
                    </div>

                    {  this.state.toggleFilterOption === 'display' ?
                    <div className='salary-filter-main report-filter-outer'>
                        <div class="filter-heading">
                            <span class="sub-heading">Filter by - Search Result : {pagination && pagination.total ? pagination.total : 0}</span>
                            <button disabled = {typeof(pagination) == 'undefined' || !pagination.total ? true : false} onClick={() => this.setState({showDownloadModal:true})} class="btn-line btn-with-loader">
                                <i class="ic-export m-xs-r"></i>
                                <span>Export Data</span>
                            </button>
                        </div>

                        <SalaryFilter 
                           filterData = {filterData}
                           getSalaryReportList = {this.getSalaryReportList}
                           /> 
                    </div>
                    : null}
                    <SalaryReportTable 
                    filterData = {filterData}
                    list={salaryReportList}
                    getSalaryReportList = {this.getSalaryReportList}
                    page= {pagination}
                    isLoading={loading}
                    />
                </div>


                <div className="import-salary-data-popup model-popup-outer">
                    <Modal show={this.state.Impportdatapopup} handleClose={this.hideModalImpportdatapopup} >
                        <div className="modal-header">
                            <h2>Import Salary Data</h2>
                        </div>
                            <FileUploader
                                onFileUpload={fileUploadCallback}
                                resetFileUpload={this.state.resetFileUpload}
                            />       
                    </Modal>
                </div>

                <div className="add-salary-data-popup model-popup-outer">
                    <Modal show={this.state.AddSalaryPaymentpopup} handleClose={this.hideModalAddSalaryPaymentpopup} >
                        <div className="modal-header">
                            <h2>Add Salary Payment</h2>
                        </div>
                        <div className="modal-body">
                          <div className='add-salary-form-filed p-md-t'>
                            <div className='row'>
                            <fieldset className={this.state.errors.npwp_number ? "required-msg form-filed col-md-6" : "form-filed col-md-6"}>
                                <div class="material">
                                    <InputMask mask='99.999.999.9-999.999' id="amount" type="text" placeholder=" " name="npwp_number" class="form-input" value={this.state.npwp_number} onChange={this.handleChange} required/>
                                        <label data-label="NPWP*" class="form-label"></label>
                                    </div>
                            </fieldset>
                            <fieldset className={this.state.errors.basic_salary ? "required-msg form-filed col-md-6" : "form-filed col-md-6"}>
                                <div class="material">
                                    <input id="amount" type="text" placeholder=" " name="basic_salary" class="form-input" value={this.state.basic_salary ? numberFormatWithDots(this.state.basic_salary) : ""} onChange={this.handleChange} required/>
                                        <label data-label="Basic Salary*" class="form-label"></label>
                                    </div>
                            </fieldset>
                            <fieldset className={this.state.errors.transportation_allowance ? "required-msg form-filed col-md-6" : "form-filed col-md-6"}>
                                <div class="material">
                                    <input id="amount" type="text" placeholder=" " name="transportation_allowance" class="form-input" value={this.state.transportation_allowance ? numberFormatWithDots(this.state.transportation_allowance) : ""} onChange={this.handleChange}/>
                                        <label data-label="Transportation Allowance" class="form-label"></label>
                                    </div>
                            </fieldset>
                            <fieldset className={this.state.errors.communication_allowance ? "required-msg form-filed col-md-6" : "form-filed col-md-6"}>
                                <div class="material">
                                    <input id="amount" type="text" placeholder=" " name="communication_allowance" class="form-input" value={this.state.communication_allowance ? numberFormatWithDots(this.state.communication_allowance) : ""} onChange={this.handleChange}/>
                                        <label data-label="Communication Allowance" class="form-label"></label>
                                    </div>
                            </fieldset>
                            
                            <fieldset class="form-filed col-md-6">
                                <div class="material">
                                    <input id="amount" type="text" placeholder=" " name="total_income" class="form-input" value={this.state.total_income ? numberFormatWithDots(this.state.total_income) : ""} disabled = {true}/>
                                        <label data-label="Total Income" class="form-label"></label>
                                    </div>
                            </fieldset>
                            <fieldset className={this.state.errors.joining_date ? "required-msg form-filed col-md-6" : "form-filed col-md-6"}>
                                <div class="material">
                                    <DOJ
                                        selected={this.state.joining_date} 
                                        onDateSelect={this.handleDateChange}
                                        required
                                        onChange={date => this.handleDateChange(date)}
                                        maxDate={new Date()}
                                        minDate={new Date("1900-01-01")}
                                        />
                                    </div>
                            </fieldset>
                            <fieldset className={this.state.errors.bro_communication_allowance ? "required-msg form-filed col-md-6" : "form-filed col-md-6"}>
                                <div class="material">
                                    <input id="amount" type="text" placeholder=" " name="bro_communication_allowance" class="form-input" value={this.state.bro_communication_allowance ? numberFormatWithDots(this.state.bro_communication_allowance) : ""} onChange={this.handleChange}/>
                                        <label data-label="BRO Communication Allowance" class="form-label"></label>
                                    </div>
                            </fieldset>
                            <fieldset className={this.state.errors.professional_allowance ? "required-msg form-filed col-md-6" : "form-filed col-md-6"}>
                                <div class="material">
                                    <input id="amount" type="text" placeholder=" " name="professional_allowance" class="form-input" value={this.state.professional_allowance ? numberFormatWithDots(this.state.professional_allowance) : ""} onChange={this.handleChange}/>
                                        <label data-label="Professional Allowance" class="form-label"></label>
                                    </div>
                            </fieldset>
                            <fieldset className={this.state.errors.incentive ? "required-msg form-filed col-md-6" : "form-filed col-md-6"}>
                                <div class="material">
                                    <input id="amount" type="text" placeholder=" " name="incentive" class="form-input" value={this.state.incentive ? numberFormatWithDots(this.state.incentive) : ""} onChange={this.handleChange}/>
                                        <label data-label="Incentive" class="form-label"></label>
                                    </div>
                            </fieldset>
                            <fieldset className={this.state.errors.bonus ? "required-msg form-filed col-md-6" : "form-filed col-md-6"}>
                                <div class="material">
                                    <input id="amount" type="text" placeholder=" " name="bonus" class="form-input" value={this.state.bonus ? numberFormatWithDots(this.state.bonus) : ""} onChange={this.handleChange}/>
                                        <label data-label="Bonus" class="form-label"></label>
                                    </div>
                            </fieldset>
                            <fieldset className={this.state.errors.salary_deduction ? "required-msg form-filed col-md-6" : "form-filed col-md-6"}>
                                <div class="material">
                                    <input id="amount" type="text" placeholder=" " name="salary_deduction" class="form-input" value={this.state.salary_deduction ? numberFormatWithDots(this.state.salary_deduction) : ""} onChange={this.handleChange}/>
                                        <label data-label="Salary Deduction" class="form-label"></label>
                                    </div>
                            </fieldset>
                            <fieldset className={this.state.errors.gross_income ? "required-msg form-filed col-md-6" : "form-filed col-md-6"}>
                                <div class="material">
                                    <input id="amount" type="text" placeholder=" " name="gross_income" class="form-input" value={this.state.gross_income ? numberFormatWithDots(this.state.gross_income) : ""} onChange={this.handleChange} required/>
                                        <label data-label="Gross Income*" class="form-label"></label>
                                    </div>
                            </fieldset>
                            <fieldset className={this.state.errors.take_home_pay ? "required-msg form-filed col-md-6" : "form-filed col-md-6"}>
                                <div class="material">
                                    <input id="amount" type="text" placeholder=" " name="take_home_pay" class="form-input" value={this.state.take_home_pay ? numberFormatWithDots(this.state.take_home_pay) : ""} onChange={this.handleChange} required/>
                                        <label data-label="Take Home Pay*" class="form-label"></label>
                                    </div>
                            </fieldset>
                            <fieldset className={this.state.errors.salary_period ? "required-msg form-filed col-md-6" : "form-filed col-md-6"}>
                                <div class="material">
                                    <SalaryPeriod   
                                        selected={this.state.salary_period} 
                                        onChange={(period) => this.handleSalaryPeriod(period)}
                                        required
                                        placeholderText="Salary Period*"
                                        maxDate={new Date()}
                                        onDateSelect={this.handleSalaryPeriod}
                                        minDate={new Date("1900-01-01")}
                                        />
                                    </div>
                            </fieldset>
                            </div>
                          </div>
                        </div>
                        <div className='modal-footer'>
                            <span><button onClick={this.handleSubmit} disabled={loading} className='btn-primary'>Add</button></span>
                            {/* <span><Loader isLoading={loading} /></span> */}
                        </div>
                    </Modal>
                </div>

                <div className="import-salary-data-popup model-popup-outer">
                    <Modal
                        show={showDownloadModal}
                        handleClose={this.hideDownloadModal}
                    >
                        <div className="modal-header">
                            <h2 style= {{ "padding-bottom" :20}}>
                            {this.props.t('SALARY_REPORT.AMOUNT_FORMAT_TYPE')}
                            </h2>
                        </div>
                        <div className="modal-body">
                            <div className="update-detail-form">
                                <fieldset className={errors.amount_format_type ? "required-msg detail-form-select-bx" : "detail-form-select-bx"}>
                                    <div className="material">
                                        <Select
                                            id="amount_format_type"
                                            onChange={(e)=> this.setState({amountFormatType:e.value})}
                                            options={AMOUNT_FORMAT_TYPE}
                                            name="amount_format_type"
                                            placeholder={this.props.t('SALARY_REPORT.AMOUNT_FORMAT_TYPE')}
                                            value={
                                                amountFormatType
                                                    ? AMOUNT_FORMAT_TYPE.filter(
                                                        ({ value }) =>
                                                            value === amountFormatType
                                                    )
                                                    : ""
                                            }
                                        />
                                        <label
                                            data-label={this.props.t('SALARY_REPORT.AMOUNT_FORMAT_TYPE')}
                                            className="form-label"
                                        ></label>

                                    </div>
                                </fieldset>


                                <div className="update-btn">
                                    <button
                                        className="btn-primary btn-with-loader"
                                        onClick={this.exportData}
                                        disabled={loading||(this.state.amountFormatType === '') ? true : false}
                                    > Download
                                    {loading ? <div className="loader loader-default is-active loader-main"></div> : null}
                                    </button>
                                </div>
                            </div>
                        </div>

                    </Modal>
                </div>
            </div>

        )
    }
}

const Modal = ({ handleClose, show, children }) => {
    const showHideClassName = show ? 'modal display-block' : 'modal display-none';

    return (
        <div className={showHideClassName}>
            <section className='modal-main'>
                {children}
                <button
                    onClick={handleClose}
                    className="close_icn"
                >
                    <i className="ic-clearclose"></i>
                </button>
            </section>
        </div>
    );                          
};
export default withTranslation("language")(SalaryReport);