import { useEffect, useRef, useState } from "react";
import { Tab, Nav } from 'react-bootstrap';
import UserList from "./UserList";
import ManageUser from "./ManageUser";
import Modal from "../../elements/Modal";
import PaymentService from '../../../service/paymentService';
import { hasAccessPermission } from "../../../config/constant";
import { Prev } from "react-bootstrap/esm/PageItem";
function UserTab(props) {
    const [userTab, setUserTab] = useState('all');
    const [userModal, setUserModal] = useState('');
    const [users, setUsers] = useState([]);
    const [userSearch, setUserSearch] = useState({ search: "" });
    const [userTabCount, setUserTabCount] = useState({ active_users: 0, all_users: 0, deleted_users: 0, inactive_users: 0 });
    const [isLoading, setIsLoading] = useState(false);
    const [editUserForm, setEditUserForm] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const [userModalAction, setUserModalAction] = useState("ADD_USER");
    const [selectedUserRole, setSelectedUserRole] = useState("");
    let inputSearch = useRef('');

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        if (props.activeTab === 'user') {
            getAllUserList(userSearch);
        }
        setUserSearch({ search: "" })
        inputSearch.current.value = "";
    }, [props]);


    const getAllUserList = async (userSearch) => {
        setIsLoading(true);
        await PaymentService.getAllUserList(userSearch).then((response) => {
            if (response && response.status === 200 && response.data.status === 200) {
                let { data } = response.data;
                setUsers(data.users);
                setUserTabCount(data.tab_count)
            }
            setIsLoading(false);
        });
    }
    /** Tab change handler */
    const handleTabChange = (ekey) => {
        setUserTab(ekey);
        let queryObj = { search: userSearch.search };
        switch (ekey) {
            case 'active':
                queryObj.status = "1";
                break;
            case 'inactive':
                queryObj.status = "0";
                break;
            case 'deleted':
                queryObj.status = "2";
                break;
            default:
                break;
        }
        setUserSearch(queryObj);
        getAllUserList(queryObj);
    }
    /** Modal Box Handler */
    const openModal = (modalId, type = "add") => {
        if (type === "add") {
            setUserModalAction('ADD_USER');
            setEditUserForm({});
        } else {
            setUserModalAction('EDIT_USER')
        }
        setUserModal(modalId);
        document.body.classList.add("overflow-hidden");
    }
    const closeModal = (modalID) => {
        setUserModal('');
        setUserModalAction("");
        setEditUserForm({});
        document.body.classList.remove("overflow-hidden");
    }
    /** Submit User Form Add/Edit */
    const submitUserForm = async (formData) => {
        setIsSubmit(true);
        if (!formData.id) {
            delete formData.id;
        }
        await PaymentService.saveUser(formData)
            .then(response => {
                if (response.status === 200 && response.data.status === 200) {
                    let { message, status } = response.data;
                    console.log(message, status);
                    getAllUserList({ search: "" });
                    closeModal('userModal');
                }
                setIsSubmit(false);
            })
    }
    /** User Click on delete of edit button from user list */
    const userActionHandler = async (userData, status, userStatus = "2") => {
        if (status === 'edit') {
            setEditUserForm({
                id: userData.id,
                name: userData.name,
                email: userData.email,
                password: "",
                role_ids: userData.user_roles ? (userData.user_roles).split(",").map(r => parseInt(r)) : [],
                mobile: userData.mobile,
                status: userData.status
            });
            setSelectedUserRole(userData.all_role);
            openModal('userModal', 'edit');
        }
        else {
            let isConfirm = window.confirm("Are you sure? you want to delete!");
            if (isConfirm) {
                let reqObj = {
                    user_id: userData.id,
                    status: userStatus,
                    update: "status"
                }
                await PaymentService.updateUserLoginStatus(reqObj).then(response => {
                    if (response.status === 200 && response.data.status === 200) {
                        let { message, status } = response.data;
                        console.log(message, status);
                        getAllUserList({ search: "" });
                    }
                })
            }
        }
    }
    /*** Search handler */
    const debounce = function (fn, d) {
        let timer;
        return function () {
            let context = this, args = arguments;
            clearTimeout(timer);
            timer = setTimeout(() => {
                fn.apply(context, args)
            }, d);
        }
    }
    const inputData = (e) => {
        let searchTxt = e.target.value;
        getAllUserList({ search: searchTxt });
        setUserSearch(() => ({ ...Prev, search: searchTxt }))
    }
    const searchInputHadler = debounce((e) => inputData(e), 1000);
    return (
        <>
            <div className="tab-chips">
                <Tab.Container defaultActiveKey={userTab} onSelect={handleTabChange}>
                    <div className="tab-chip-outer">
                        <Nav variant="pills" className="flex-column">
                            <Nav.Item>
                                <Nav.Link active={userTab === 'all'} eventKey="all">{props.t("USER_ROLE.ALL_USER")}<span>{(userTabCount && userTabCount.all_users) || 0}</span></Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link active={userTab === 'active'} eventKey="active">{props.t("USER_ROLE.ACTIVE_USER")}<span>{(userTabCount && userTabCount.active_users) || 0}</span></Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link active={userTab === 'inactive'} eventKey="inactive">{props.t("USER_ROLE.INACTIVE_USER")}<span>{(userTabCount && userTabCount.inactive_users) || 0}</span></Nav.Link>
                            </Nav.Item>
                            {/* <Nav.Item>
                                <Nav.Link active={userTab == 'deleted'} eventKey="deleted">{props.t("USER_ROLE.DELETED_USER")}<span>{userTabCount && userTabCount.deleted_users || 0}</span></Nav.Link>
                            </Nav.Item> */}
                        </Nav>
                        <div className="search-outer">
                            <input type="text" name="search" ref={inputSearch} onInput={searchInputHadler} placeholder="Search by name" className="form-input" />
                            <i className="search-icn ic-search"></i>
                        </div>
                        {hasAccessPermission('user-role', 'edit') ?
                            <div className="add-utr-btn">
                                <button className="btn-line" onClick={() => openModal('userModal')}> {props.t('USER_ROLE.ADD_USER')}</button>
                            </div> : null}
                    </div>
                    <div>
                        <Tab.Content>
                            <Tab.Pane eventKey={userTab}>
                                <UserList isLoading={isLoading} users={users} userAction={userActionHandler} />
                            </Tab.Pane>
                        </Tab.Content>
                    </div>
                </Tab.Container >
            </div>
            {userModalAction === "EDIT_USER" ?
                <div className="role-outer">
                    <div className="role-container">
                       { /* eslint-disable jsx-a11y/anchor-is-valid */}
                        <a className="close_icn" onClick={() => closeModal('userModal')}>
                            <i className="ic-clearclose"></i>
                        </a>
                        {/* eslint-enable jsx-a11y/anchor-is-valid */}
                        <h2>{props.t("USER_ROLE." + userModalAction)}</h2>
                        <ManageUser
                            t={props.t}
                            formModalStatus={userModal}
                            submitForm={submitUserForm}
                            isLoading={isLoading}
                            userForm={editUserForm}
                            isSubmit={isSubmit}
                            selectedUserRole={selectedUserRole}
                        />
                    </div>
                </div> : <div className="update-pyament-detail-form utr-upload-popup">
                    <Modal show={userModal} handleClose={() => closeModal('userModal')} >
                        <div className="modal-header"><h2>{props.t("USER_ROLE.ADD_USER")}</h2></div>
                        <div className="modal-body"><br />
                            <ManageUser
                                t={props.t}
                                formModalStatus={userModal}
                                submitForm={submitUserForm}
                                isLoading={isLoading}
                                userForm={editUserForm}
                                isSubmit={isSubmit}
                            />
                        </div>
                    </Modal>
                </div>
            }
        </>
    )
}
export default UserTab;