import MasterService from "./MasterService";

const commonService = {
	...MasterService,
	// THIS IS SAMPLE SERVICE FUNCTIO
	getFinancierList() {
		return this.get("/common/financier_list");
	},
	loginCaptcha(){
		return this.get("/user/getCaptchaImage");
	}
};
export default commonService;