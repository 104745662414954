import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import InfiniteScroll from 'react-infinite-scroll-component';
import ManageRole from "./ManageRole";
import PaymentService from '../../../service/paymentService';
import { hasAccessPermission } from "../../../config/constant";
import { toast } from "react-toastify";
let intialRoleForm = {
    id: "",
    role_name: "",
    description: "",
    permissions: []
}
function RoleList(props) {
    const { t } = useTranslation('language');
    const [hasMoreItems, ] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [roleLists, setRoleLists] = useState([]);
    const [roleFeatures, setRoleFeatures] = useState([]);
    const [roleForm, setRoleForm] = useState(intialRoleForm);
    const [userRoleModal, setUserRoleModal] = useState("");
    const [roleAction, setRoleAction] = useState("ADD_ROLE");

    useEffect(() => {
        if (props.activeTab === 'role') {
            getAllRoles();
        }
    }, [props])

    const openModal = (modalId, type = 'ADD_ROLE') => {
        setRoleAction(type);
        setUserRoleModal(modalId);
        if (type === 'ADD_ROLE') getAllRoleFeatures(0);
        document.body.classList.add("overflow-hidden");
    }
    const closeModal = (modalID) => {
        setUserRoleModal('');
        setRoleFeatures([]);
        setRoleForm(intialRoleForm);
        document.body.classList.remove("overflow-hidden");
    }

    const getAllRoleFeatures = async (role_id) => {
        await PaymentService.featureList({ role_id })
            .then(response => {
                if (response && response.status === 200 && response.data.status === 200) {
                    let { data } = response.data;
                    setRoleFeatures(data);
                }
            })
    }

    const getAllRoles = async () => {
        setIsLoading(true);
        await PaymentService.getAllUserRolesList({ role_data: ['role_list'] })
            .then(response => {
                if (response && response.status === 200 && response.data.status === 200) {
                    let { data } = response.data;
                    setRoleLists(data.roles);
                }
                setIsLoading(false);
            })
    }
    const loadMoreItems = () => { }

    const submitRoleForm = async (formData) => {
        if (!formData.id) {
            delete formData.id;
        }
        await PaymentService.saveRole(formData)
            .then(response => {
                let { message } = response.data;
                if (response && response.status === 200 && response.data.status === 200) {
                    getAllRoles();
                    toast.success(message);
                    closeModal('roleModal');
                    setRoleForm(intialRoleForm);
                }else {
                    toast.error(message);
                }
            })
    }
    const editRole = (roleData) => {
        setRoleForm({
            id: roleData.id,
            role_name: roleData.role_name,
            description: roleData.description
        })
        getAllRoleFeatures(roleData.id);
        openModal('roleModal', 'EDIT_ROLE')
    }
    const deleteRole = async (roleData) => {
        let isConfirm = window.confirm("Are you sure? you want to change status!");
        if (isConfirm) {
            let reqObj = {
                role_id: roleData.id,
                status: roleData.status === "1" ? "0" : "1"
            }
            await PaymentService.updateRoleStatus(reqObj)
                .then(response => {
                    let { message } = response.data;
                    if (response && response.status === 200 && response.data.status === 200) {
                        toast.success(message);
                        getAllRoles();
                    }
                    else {
                        toast.error(message);
                    }
                })
        }
    }
    return (
        <>
            {hasAccessPermission('user-role', 'edit') ?
                <div className="tab-chip-outer">
                    <div className="flex-column nav nav-pills"></div>
                    <div className="add-utr-btn">
                        <button className="btn-line" onClick={() => openModal('roleModal')}> {t('USER_ROLE.ADD_ROLE')}</button>
                    </div>
                </div> : null}
            <div id="scrollableDiv" >
                <InfiniteScroll
                    dataLength={roleLists.length}
                    next={loadMoreItems}
                    hasMore={hasMoreItems || false}
                    loader={isLoading ? <div className="loader loader-default is-active loader-main" data-text="loading"></div> : null}
                >
                    <div className="loan-table-data">
                        <table>
                            <thead>
                                <tr>
                                    <th>{t('USER_ROLE.ROLE')}</th>
                                    <th>{t('USER_ROLE.DESCRIPTION')}</th>
                                    <th>{t('USER_ROLE.USER_ASSIGNED')}</th>
                                    <th>{t('USER_ROLE.FEATURE_ACCESS')}</th>
                                    <th>{t('USER_ROLE.STATUS')}</th>
                                    <th>{t('USER_ROLE.ACTION')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {!isLoading && roleLists.length ? roleLists.map((role) => {
                                    return (
                                        <tr key={role.id}>
                                            <td>{role.role_name}</td>
                                            <td>{role.description}</td>
                                            <td>{role.user_assigned_count} Users</td>
                                            <td>{role.total_features} Features</td>
                                            <td><span className={(role.status === "1" ? "active" : "inactive") + "-dot"}>{role.role_status}</span></td>
                                            <td>
                                                {hasAccessPermission('user-role', 'edit') ?
                                                    <div className="d-flex">
                                                        <button className="btn-status" onClick={() => deleteRole(role)}>{t('USER_ROLE.CHANGE_STATUS')}</button>&nbsp;
                                                        <button className="btn-view" onClick={() => editRole(role)}>{t('USER_ROLE.EDIT')}</button>
                                                    </div> :
                                                    <div className="d-flex">
                                                        <button className="btn-status" disabled>{t('USER_ROLE.CHANGE_STATUS')}</button>&nbsp;
                                                        <button className="btn-view" disabled>{t('USER_ROLE.EDIT')}</button>
                                                    </div>
                                                }
                                            </td>
                                        </tr>
                                    )
                                }) : <tr>
                                    <td colSpan="6" className="txt-center">{isLoading ?
                                        <div className="loader loader-default is-active loader-main" data-text="loading"></div> :
                                        t('USER_ROLE.NOT_FOUND')}
                                    </td>
                                </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </InfiniteScroll>
            </div>
            {userRoleModal ?
                <div className="role-outer">
                    <div className="role-container">
                        { /* eslint-disable jsx-a11y/anchor-is-valid */}
                        <a className="close_icn" onClick={() => closeModal('roleModal')}>
                            <i className="ic-clearclose"></i>
                        </a>
                        {/* eslint-enable jsx-a11y/anchor-is-valid */}
                        <h2>{t("USER_ROLE." + roleAction)}</h2>
                        <ManageRole
                            t={t}
                            formModalStatus={userRoleModal}
                            submitRole={submitRoleForm}
                            roleForm={roleForm}
                            roleFeatures={roleFeatures}
                        />
                    </div>
                </div> : null
            }
        </>
    )
}
export default RoleList;