import React, { Component } from 'react';
import Select from 'react-select';
import { SalaryPeriod } from "./DatePicker"
import { toast } from "react-toastify";
import InputMask from 'react-input-mask';
import dateFormat from 'dateformat';
import { DATE_FORMAT_INPUT } from "../../config/constant";
import DatePicker  from "../elements/DatePicker"
// const { ValueContainer, Placeholder } = components;
export const DATE_FORMAT_FILTER = "yyyy-mm-dd";

const DateType = [
    { value: 'DOJ', label: 'DOJ' },
];

class SalaryFilter extends Component {
    constructor(props) {
        super(props);

        this.state = {
            filterData : {},
            filterArr : [],
            loading : false
        };
    }

    static getDerivedStateFromProps (props, state) {
        return {
            getSalaryReportList : props.getSalaryReportList,
            filterData : props.filterData
        }
    }

    componentDidMount = () => {
        this.onFilterSubmit();   
    }

    handleFilterChange = (sname, ovalue, isMulti, opt, optl) => {
        let { filterData } = this.state;
        if (sname === "npwp_number") {
            filterData[sname] = ovalue;
        }
        else if (sname === 'date_filter_type') {
                filterData[sname] = ovalue;
        }
        else if (sname === 'fromDate') {
            filterData[sname] = dateFormat(ovalue, DATE_FORMAT_FILTER);
        }
        else if (sname === 'toDate') {
            filterData[sname] = dateFormat(ovalue, DATE_FORMAT_FILTER);
        }
        else if (sname === 'salary_period') {
            const convert = (salary_period) => {
               var date = new Date(salary_period)
               let mnth = ("0" + (date.getMonth() + 1)).slice(-2);
               return [mnth , date.getFullYear() ].join("/");
             }
             filterData[sname] = convert (ovalue)
             filterData["salary_period_to_show"] = (ovalue)
        }
        this.setState({ filterData: filterData });
    }

    onFilterReset = () => {
        this.setState({
            filterArr: [],
            filterData: {},
            salary_period: "",
            loading: true
        }, () => {
            this.state.getSalaryReportList({ page: 1 });
        });
    };

    
    removeFilter = (value, key) => {
        let { filterArr, filterData } = this.state;
        if (key === "salary_period") {
            delete filterData[key];
            delete filterData["salary_period_to_show"]
        }
        if (key === "npwp_number") {
            delete filterData[key];
        }
        // Removing selected dates if date type filter is removed
        if (key === "date_filter_type") {
            delete filterData[key];
            delete filterData["fromDate"];
            delete filterData["toDate"];
            let fromDateIndx = filterArr.findIndex(item => item.key === "fromDate");
            let toDateIndx = filterArr.findIndex(item => item.key === "toDate");
            filterArr.splice(fromDateIndx, 1);
            filterArr.splice(toDateIndx, 1);
        }
        if(key === "toDate" || "fromDate"){
            delete filterData[key];
        }
        let indx = filterArr.findIndex(itm => itm.key === key && itm.value === value);
        filterArr.splice(indx, 1);
        this.setState({ filterArr, filterData }, () => {
            this.onFilterSubmit();
        });
    }

    onFilterSubmit = () => {
    let { filterData } = this.state;
    var arr = [];
    for (const key in filterData) {
        if (Object.hasOwnProperty.call(filterData, key)) {
            let element = filterData[key];
            let labeltxt = '';
            if (['fromDate', 'toDate'].includes(key)) {
                if (key === 'fromDate' && element !== "") {
                    labeltxt = 'From Date';
                }
                if (key === 'toDate' && element !== "") {
                    labeltxt = 'To Date';
                }
                arr.push({ labeltxt: labeltxt, label: element, value: element, key: key });
            }
            if (['date_filter_type'].includes(key)) {
                let labeltxt = ''; 
                if (key === 'date_filter_type') {
                    labeltxt = "Date Type";
                    arr.push({ labeltxt: labeltxt, label: element, value: element, key: key });
                } 
            }
            if (key === 'npwp_number') {
                    labeltxt = 'NPWP';
                if (element !== '') {
                    if(((/^\d\d\.\d\d\d\.\d\d\d\.\d-\d\d\d\.\d\d\d$/i.test(element)))){
                        arr.push({ labeltxt: labeltxt, label: element, value: element, key: key})
                    }else {
                        return toast.error("Please enter a valid NPWP");
                    }
                }
                
            }
            
            // To check for empty date data
            if (filterData.hasOwnProperty("date_filter_type") && filterData.date_filter_type !== '') {
                if (!filterData.fromDate) {
                    return toast.error("Please select from date");
                } else if (!filterData.toDate) {
                    return toast.error("Please select to date");
                }
            }
            if(key === 'salary_period') {
                labeltxt = 'Salary Period';
                if (element !== '') {
                    arr.push({ labeltxt: labeltxt, label: element, value: element, key: key})
                }
            }
            if (element === '' || element.length === 0) {
                delete filterData[key];
            }
        }
    }
    filterData = {
        ...filterData,
        page: 1
    };
    this.setState({ filterData: filterData, filterArr: arr, loading: true }, () => {
        this.state.getSalaryReportList(filterData)
    });
}

    render() {

        const { filterData, filterArr } = this.state;
        console.log(Object.keys(filterData).length)
        
        return (

<div>
            <div className="salary-report-filter-outer ">
                <fieldset class="form-filed searchbox">
                    <div class="material">
                    <InputMask mask='99.999.999.9-999.999' id="" type="text" placeholder=" " name="RoleName" class="form-input" value={filterData.npwp_number || ""} onChange={e => this.handleFilterChange("npwp_number", e.target.value)} />
                        <label data-label="Search By NPWP" class="form-label"></label>
                        <i className='ic-search'></i>
                    </div>
                </fieldset>
                <fieldset className="single-select">
                    <div className="material">
                        <Select
                            value={filterData.date_filter_type ? DateType.filter(({ value }) => value === filterData.date_filter_type) : ""}
                            onChange={e => this.handleFilterChange("date_filter_type", e.value)}
                            options={DateType}
                            placeholder="Date Type"
                            className="react-select"
                            classNamePrefix="react-select"
                        />
                    </div>
                </fieldset>

                <fieldset className="single-select">
                    <div className="material">
                    <DatePicker
                        id="fromDate"
                        name="fromDate"
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        autoComplete="off"
                        placeholderText="From Date"
                        selected={filterData.fromDate ? new Date(filterData.fromDate) : ""}
                        onChange={(date) => this.handleFilterChange('fromDate', date)}
                        maxDate={new Date()}
                        onKeyDown={(e) => e.preventDefault()}
                        dateFormat={DATE_FORMAT_INPUT}
                        disabled={filterData.date_filter_type ? false : true}
                    />
                        {/* <Calendra2
                            selected={fromDate}
                            onChange={(date) => this.handleFromDateChange(date)}
                        /> */}
                    </div>
                </fieldset>
                <fieldset className="single-select">
                    <div className="material">
                    <DatePicker
                        id="toDate"
                        name="toDate"
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        autoComplete="off"
                        placeholderText="To Date"
                        selected={filterData.toDate ? new Date(filterData.toDate) : ""}
                        onChange={(date) => this.handleFilterChange('toDate', date)}
                        onKeyDown={(e) => e.preventDefault()}
                        dateFormat={DATE_FORMAT_INPUT}
                        maxDate={new Date()}
                        minDate={new Date(filterData.fromDate)}
                        disabled={filterData.fromDate ? false : true}
                    />

                        {/* <Calendra1
                            selected={toDate}
                            onChange={(date) => this.handleToDateChange(date)}
                        /> */}
                    </div>
                </fieldset>
                <fieldset className="salary-period-picker">
                    <div className="material">
                        <SalaryPeriod
                            value={filterData.salary_period_to_show ? filterData.salary_period_to_show : ""}
                            selected={filterData.salary_period_to_show ? filterData.salary_period_to_show : ""}
                            placeholderText="Salary Period"
                            onChange={(date) => this.handleFilterChange('salary_period', date)}
                        />
                    </div>
                </fieldset>

                <div class="btn-submit-reset">
                    <button class="btn-primary" onClick={this.onFilterSubmit}>Filter Report</button>
                    <button class="btn-reset" onClick={this.onFilterReset}>Reset</button>
                </div>
            </div>
            <div className="selected-chips">
                            <ul>
                                {
                                    (filterArr && filterArr.length > 0) ?
                                        // eslint-disable-next-line array-callback-return
                                        filterArr.map((item, index) => {
                                            if (item.label !== '') {
                                                return (
                                                    <li key={index}>
                                                        {item.labeltxt} - {item.label}
                                                        <span onClick={() => this.removeFilter(item.value, item.key)}>
                                                            <i className="ic-clearclose"></i>
                                                        </span>
                                                    </li>
                                                )
                                            }
                                        }) :
                                        null
                                }
                            </ul>
                        </div>
                </div>

        )
    }
}
export default SalaryFilter;