import React, { useEffect, useState } from "react";
import {  useDispatch } from "react-redux";
import { UserActions } from "../../store/action";
import InputField from "../elements/InputField";

const Captcha = (props) => {

    const [captcha, setCaptcha] = useState('')
    const [captchaInvalid, setCaptchaInvalid] = useState('')
    const [captchaImg, setCaptchaImg] = useState({})

    const dispatch = useDispatch();
    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        loadCaptchaImage()
    }, [props.reloadCaptcha])

    useEffect(()=> {
        setCaptchaInvalid(props.errField.captcha)
    }, [props.errField])


    /**
     *  Load Captcha Image
     */

    const loadCaptchaImage = (err = []) => {
        setCaptcha("");

        dispatch(UserActions.loginCaptcha()).then(res=>{
            if(res && res.data && res.data.data){
				setCaptchaImg(res.data.data)
            }
        })
                
    };

    /**
     * Input Handler
     * @param e (synthatic element)
     */
    const handleInputChange = async e => {
        let captchaVal = e.target.value;
		setCaptcha(captchaVal)
        props.onKeyPressCaptcha(captchaVal);        
    };

    /**
     * Reset Captcha Error
     */
    // const resetCaptchaError = async () => {
    //     if(this._isMount){
    //     await this.setState({
    //         captchaInvalid: ""
    //     });
    //     }
    // };


    const { captchaError } = props;
    
    return (
      <>
        <div className={captchaInvalid ? "material error": "material"}>
            {/* <div className={"col-md-6"}> */}
              {/* <div className={"col-md-2"}> */}
                <InputField
                  inputProps={{
                    id: "captcha",
                    type: "text",
                    name: "captcha",
                    placeholder: "Captcha",
					label: "Captcha",
                    value: captcha,
                    className: "form-input",
					maxLength:6,
                  }}
                  onChange={handleInputChange}
                />

                <i className="ic-survey icn-login"></i>

                <label data-label="" className="form-label"></label>
				<span className="erroe-txt error-captcha" >
								{captchaInvalid||''}
							</span>
                <span className="password-show" style={{ left: "225px","fontSize": '20px'}}>
                  {captchaError ? (
                    ''
                  ) : (
                    <>
                      <img src={`data:image/svg+xml;base64,${window.btoa(
                                        captchaImg.image
                                    )}`} className="captchaimg" alt=""/>
                      <span
                        className="reload"
                        onClick={loadCaptchaImage}
                        title="reload captcha"
                      >
                        &#x21bb;
                      </span>
                    </>
                  )}
                </span>
        </div>
      </>
    );
}

export default Captcha;