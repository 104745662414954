import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import { withTranslation } from "react-i18next";
import secureStorage from "./../../config/encrypt";
import PaymentService from "../../service/paymentService";
import { connect } from "react-redux";
import { hasAccessPermission } from "../../config/constant";
import { logout } from "../../store/allAction";
import store from '../../store/store';
import { Navigate } from "react-router";
class Navigation extends Component {
	constructor() {
		super();
		this.state = {
			lang_name: secureStorage.getItem("lang") === "id" ? "ID" : "EN",
			redirect: false,
			name: "",
			role: "",
			loggedIn: false,
			authUserInfo : secureStorage.getItem("authUserInfo")
		};
	}

	static getDerivedStateFromProps = (nextProps, state) => {
		const updatedProps = {};
		if (
			nextProps.loggedIn !== state.loggedIn
		) {
			updatedProps["loggedIn"] = nextProps.loggedIn;
		}
		return updatedProps
	};

	componentDidMount() {
		let result = secureStorage.getItem("authUserInfo");
		if (!result) {
			this.setState({ redirect: true });
		} else {
			let userInfo = result.user_data;
			this.setState({ name: userInfo.name, role: userInfo.role_name });
		}
	}
	changeLanguage = (land_id) => {
		let lang_name = land_id === "id" ? "ID" : "EN";
		this.setState({ lang_name: lang_name });
		secureStorage.setItem("lang", land_id);
		this.props.i18n.changeLanguage(land_id);
	};

	logOutUser = async () => {
		let result = secureStorage.getItem("authUserInfo");
		if (result) {
			let response = await PaymentService.userLogOut({
				user_id: result.user_data.user_id,
				token: result.token,
			});
			//return false;
			if (response && response.status === 200) {
				this.props.navigate('/login')
				secureStorage.removeItem("authUserInfo");
				secureStorage.removeItem("userAccess");
				secureStorage.removeItem("currencyInfo");
				store.dispatch(logout())
				toast.success(`LogOut Successfully`);
			}
		}
	};
	
	render() {
		// const { redirect,authUserInfo } = this.state;
		const { redirect } = this.state;
		if (redirect) {
			return <Navigate to="/login" />;
		}
		//let is_access_role = authUserInfo && authUserInfo.user_data ?  +authUserInfo.user_data.is_access_role : 0
		let dashboard_url = (hasAccessPermission('salary-report')) ? `/salary-report` : '/incentive/pending/incentive_payment'
		return (
			<div className="container">
				<div className="header-main">
					<div className="logo">
						<NavLink to={dashboard_url}>
							{this.props.t("COMMON.SITE_TITLE")}
						</NavLink>
					</div>
					<div className="right-panel-naviganion menupanel">

						<div className="nav">
							<ul>
								<li>
									<NavLink to={dashboard_url}>
										{this.props.t("COMMON.DASHBOARD")}
									</NavLink>
								</li> 
								{/* <li>
									<NavLink to="/incentive/pending/incentive_payment">
										{this.props.t("COMMON.INCENTIVE")}
									</NavLink>
								</li>  */}
							</ul>
						</div>
						{/* <a className="notification-btn">
							<Notification />

						</a> */}
						<div className="user-detail  login-detail">
							<ul>
								<li className="dropdown">
									<NavLink to="#">
										{" "}
										<span className="userarc">
											{this.state.name.charAt(0)}
										</span>
										<div className="username">
											<span className="">
												{this.state.name}
											</span>
											
										</div>
									</NavLink>
									<ul className="submenu">

										<li>
											<NavLink to="#" onClick={this.logOutUser.bind()}>
												LogOut
											</NavLink>{" "}
										</li>
									</ul>
								</li>
							</ul>
						</div>

						
						<div className="language">
							<ul>
								<li className="dropdown">
									<NavLink to="#">
										{this.state.lang_name}
									</NavLink>
									<ul className="submenu">
										<li>
											<NavLink
												to="#"
												onClick={this.changeLanguage.bind(
													"language",
													"id"
												)}
											>
												ID
											</NavLink>{" "}
										</li>
										<li>
											<NavLink
												to="#"
												onClick={this.changeLanguage.bind(
													"language",
													"en"
												)}
											>
												EN
											</NavLink>{" "}
										</li>
									</ul>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
const mapStateToProps = ({ user }) => {
	return {
		loginStatus: (user && user.loginStatus) ? user.loginStatus : { login_status: 'active', away_time: 0 },
		loggedIn: user.loggedIn
	};
};
export default connect(mapStateToProps)(withTranslation("language")(Navigation));
