import React, { Component } from 'react';
import { Tab, Nav } from 'react-bootstrap';
import logo_login from "../../webroot/images/login_logo.svg";
class LoginOtp extends Component {
    constructor(props) {
        super(props);
        console.log(props)

        this.state = {

        };
    }


    render() {

        return (
            <div>
                <div className="container-fluid">
                    <div className="login-outer">
                        <div className="login-left-panel">
                            <div className="top-logo">
                                <img src={logo_login} className="" alt='' />
                            </div>
                            <div className="heading-txt">
                                <span>Welcome to</span>
                                <h1>Payment Box</h1>
                            </div>
                        </div>
                        <div className="login-right-panel">
                            <h2>Login</h2>
                            <Tab.Container id="" defaultActiveKey="first">
                                <div className="login-tab">
                                    <Nav variant="pills" className="flex-column">
                                        <Nav.Item>
                                            <Nav.Link eventKey="first">By Email</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="second">By Mobile</Nav.Link>
                                        </Nav.Item>
                                    </Nav>

                                    <Tab.Content>
                                        <Tab.Pane eventKey="first">
                                            <div className="login-form">
                                                <fieldset className="">
                                                    <div className="material">
                                                        <input id="" type="text" placeholder=" " name="Email" className="form-input" />
                                                        <i className="ic-email1 icn-login"></i>
                                                        <label data-label="Email" className="form-label"></label>
                                                    </div>
                                                </fieldset>
                                                <fieldset className="">
                                                    <div className="material error">
                                                        <input id="" type="text" placeholder=" " name="Password" className="form-input" />
                                                        <i className="ic-password icn-login"></i>
                                                        <label data-label="Password" className="form-label"></label>
                                                        <span className="erroe-txt">Please type correct password</span>
                                                    </div>
                                                </fieldset>
                                                <button type="button" className="btn-primary">Login</button>
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="second">
                                            <div className="login-form">
                                                <fieldset className="">
                                                    <div className="material">
                                                        <input id="" type="text" placeholder=" " name="Mobile No." className="form-input" />
                                                        <i className="ic-mobile icn-login"></i>
                                                        <label data-label="Mobile No." className="form-label"></label>
                                                    </div>
                                                </fieldset>

                                                <button type="button" className="btn-primary">Get OTP</button>
                                            </div>
                                        </Tab.Pane>


                                    </Tab.Content>
                                </div>
                            </Tab.Container>
                        </div>
                    </div>
                </div>
            </div>


        );
    }
}


export default LoginOtp; 