import React, { useEffect, useState } from 'react';
import { Tab, Nav } from 'react-bootstrap';
import IncentivePayment from "./IncentivePayment"
import { useParams } from 'react-router';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const OutbountReportTab = () => {
    let history = useNavigate();
    let { tab, subtab } = useParams();
    const { t } = useTranslation('language');
    const [slctTab, setSlctTab] = useState(tab);
    useEffect(() => {
        setSlctTab(tab);
    }, [tab]);
    
    const handleSelectTab = (eventKey) => {
        setSlctTab(eventKey);
        history(`/incentive/${eventKey}/${subtab}`);
    }
    return (
        <div className="finance-report-outer">
            <div className="white-bg">
                <div className="container">
                    <div className="top-heading">
                        <h1>{t('INCENTIVE_PAYMENT.TITLE')}</h1>
                    </div>
                </div>
            </div>
            <Tab.Container id="left-tabs-example" defaultActiveKey={slctTab} onSelect={handleSelectTab}>
                <div className="white-bg">
                    <div className="container">
                        <Nav variant="pills" className="flex-column tab-line financer-tab-line">
                            <div className="tab-list">
                                <Nav.Item>
                                    <Nav.Link active={slctTab === 'pending'} eventKey="pending">{t('INCENTIVE_PAYMENT.PAYMENT_PENDING')}</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link active={slctTab === 'paid'} eventKey="paid">{t('INCENTIVE_PAYMENT.PAYMENT_PAID')}</Nav.Link>
                                </Nav.Item>
                            </div>
                        </Nav>
                    </div>
                </div>
                <div className="container">
                    <Tab.Content>
                        <Tab.Pane active={slctTab} eventKey={slctTab}>
                                <IncentivePayment parentTabStatus={slctTab} />
                            
                        </Tab.Pane>
                    </Tab.Content>
                </div>
            </Tab.Container>
        </div>
    )
}
export default OutbountReportTab;