import React, { useEffect, useState } from 'react'
import { useTranslation, withTranslation } from "react-i18next";
import DateFormate from "dateformat";
import { Tab, Nav } from 'react-bootstrap';

const INVOICE_STATUS = [
    { value: '1', label: 'Success' },
    { value: '2', label: 'Manual Success' },
    { value: '3', label: 'Rejected' },
    { value: '4', label: 'Failed' },
];

const FINCARE_STATUS = [
    { value: '7', label: 'Open', subStatus: 'Pending'},
    { value: '8', label: 'Closed', subStatus: 'Payment Done'}
];

function LoanHistory({ historyList, isLoading }) {
    useEffect(()=>{
        if(!isLoading){ 
            setSelectedTab('frpm');
        }
    },[isLoading,historyList])

    const { t } = useTranslation('language');
    const [selectedTab, setSelectedTab] = useState('frpm');
    const historyData = historyList.filter(fincareData => fincareData.type === selectedTab);
    console.log('selectedTab=====',selectedTab,'historyData=======',historyData)
    return (
        <>
            <div className="modal-header">
                <h2>{t("INCENTIVE_PAYMENT.VIEW_HISTORY")}</h2>
            </div>
            <Tab.Container id="left-tabs-example" defaultActiveKey={selectedTab} onSelect={setSelectedTab}>
                <Nav variant="pills" className="flex-column tab-line financer-tab-line">
                    <div className="tab-list">
                        <Nav.Item>
                            <Nav.Link active={selectedTab === 'frpm'} eventKey="frpm">Incentive Tranche History</Nav.Link>
                        </Nav.Item>
                        
                        <Nav.Item>
                            <Nav.Link active={selectedTab === 'fincare'} eventKey="fincare">Fincare History</Nav.Link>
                        </Nav.Item>
                        
                    </div>
                </Nav>
                <Tab.Content>
                    {/* <Tab.Pane eventKey={selectedTab}> */}
                        <table>
                            <tbody>
                                {historyData && historyData.length ? (
                                        historyData
                                        .map(history => (
                                            <tr key={history.id}>
                                                <td>
                                                    <span className="date">
                                                    {history && history.created_date ?
                                                        DateFormate(history.created_date, "dd mmm") : ""}
                                                    </span>
                                                    <span className="time">
                                                        {history && history.created_date ?
                                                            DateFormate(history.created_date, "h:MM TT") : ""}
                                                    </span>
                                                </td>
                                                {selectedTab === 'frpm' ?
                                                    <td>
                                                        <span className="heading">{(history && history.status_name) || ''} </span>
                                                        <span>{(history && INVOICE_STATUS.filter(data => +data.value === +history.invoice_status).map(v => v.label)[0]) || "" } </span>
                                                    </td>
                                                    :
                                                    <td>
                                                        <span className="date">
                                                            {history && (history.status_id) ?
                                                                FINCARE_STATUS
                                                                .filter(fincare_status => +fincare_status.value === +history.status_id)
                                                                .map(fincare_status => fincare_status.label)  : ''}
                                                            </span>
                                                        <span className="time">
                                                            {history && history.fincare_substatus ?
                                                                history.fincare_substatus : ""
                                                            }
                                                        </span>
                                                    </td>
                                                }
                                        <td>{(history && history.user_name) || ''}</td>
                                    </tr>
                                    ))
                                ) : (<tr>
                                        <td colSpan="5" style={{ color: 'red' }}>
                                            {isLoading ? <div className="loader loader-default is-active loader-main" data-text="loading"></div> : t('COMMON.NOT_FOUND')}
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    {/* </Tab.Pane> */}
                    {/* <Tab.Pane eventKey="fincare">
                        <table>
                            <tbody>
                                
                                {historyData && historyData.length ? (
                                    historyData
                                    .map(fincareData => ( 
                                            
                                        <tr key={fincareData.id}>
                                            <td>
                                                <span className="date">
                                                {fincareData && fincareData.created_date ?
                                                    DateFormate(fincareData.created_date, "dd mmm") : ""}
                                                </span>
                                            <span className="time">
                                                {fincareData && fincareData.created_date ?
                                                    DateFormate(fincareData.created_date, "h:MM TT") : ""}
                                            </span>  
                                            </td>
                                            <td>
                                                <span className="date">
                                                    {fincareData && (fincareData.status_id || fincareData.status_id === 0) ?
                                                        FINCARE_STATUS
                                                        .filter(fincare_status => +fincare_status.value === +fincareData.status_id)
                                                        .map(fincare_status => fincare_status.label)  : ''}
                                                    </span>
                                                <span className="time">
                                                    {fincareData && fincareData.fincare_substatus ?
                                                        fincareData.fincare_substatus : ""
                                                    }
                                                </span>
                                            </td>
                                        </tr>
                                    ))
                                    ) : (<tr>
                                        <td colSpan="5" style={{ color: 'red' }}>
                                            {isLoading ? <div className="loader loader-default is-active loader-main" data-text="loading"></div> : t('COMMON.NOT_FOUND')}
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </Tab.Pane> */}
                </Tab.Content>
            </Tab.Container>
        </>
    )
}

export default withTranslation("language")(LoanHistory);
