import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import PaymentService from "../../service/paymentService";
import secureStorage from "./../../config/encrypt";
import { useSelector, useDispatch } from "react-redux";
import { userDetail } from '../../store/allAction';
import { hasAccessPermission } from "../../config/constant";
import Captcha from "../common/Captcha";
// import { IS_REDIS } from "../../config/constant";

function MobileUser() {
	const captchaData = useSelector((state) => {
		return state.user;
	})
	const [reloadCaptcha, setReloadCaptcha] = useState(1);

	const btnRefs = useRef()
	const history = useNavigate();
	const dispatch = useDispatch()
	const time = 59;
	const [counter, setCounter] = React.useState(time);
	
	const [inputField, setInputField] = useState( captchaData.is_radis === true ? {
		mobile: "",
		password: "",
		captcha:"",
		otp:"",
	} : {
		mobile: "",
		password: "",
		otp:"",
	});
	const [otpFlag, setFlag] = useState(true);
	const [resendOtp, setResend] = useState(false);

	/* eslint-disable react-hooks/exhaustive-deps */
	useEffect(() => {
		if (counter === 0) {
			setResend(true);
		}
	}, [counter === 0]);

	const inputHandler = (e) => {
		setInputField({ ...inputField, [e.target.name]: e.target.value });
	};
	const [errField, setErrField] = useState({
		mobileErr: "",
		otpErr: "",
		captchaErr: "",
		passwordErr: "",

	});
	const validForm = (type) => {
		let formIsValid = true;
		setErrField({
			mobileErr: "",
			otpErr: "",
			captchaErr: "",
			passwordErr: "",

		});
		if (type === "mobile") {
			if (inputField.mobile === "") {
				formIsValid = false;
				setErrField((prevState) => ({
					...prevState,
					mobileErr: "Please Enter Mobile",
				}));
			} else {
				let mobileRule = /^[8]\d{8,11}$/;
				if (!mobileRule.test(inputField.mobile)) {
					formIsValid = false;
					setErrField((prevState) => ({
						...prevState,
						mobileErr: "Please Enter Valid Mobile No",
					}));
				}
			}
			if (inputField.password === "") {
				formIsValid = false;
				setErrField((prevState) => ({
					...prevState,
					passwordErr: "Please Enter Password",
				}));
			}
		} else if (inputField.otp === "") {
			formIsValid = false;
			setErrField((prevState) => ({
				...prevState,
				otpErr: "Please Enter OTP",
			}));
		}
		return formIsValid;
	};

	const submitButton = async () => {
		if (btnRefs.current) btnRefs.current.setAttribute("disabled", "disabled");
		if (validForm()) {
			let response = await PaymentService.userLogin(inputField);
			if (response && response.status === 200) {
				setReloadCaptcha((prev) => prev + 1)
				let { permissions } = response.data.data;
				let userInfo = response.data.data;
				secureStorage.setItem("authUserInfo", userInfo);
				secureStorage.setItem("userAccess", permissions);
				dispatch(userDetail(userInfo.user_data));
				toast.success(`Login Successfully`);
				//let authUser = userInfo.user_data;
				let url2redirct = (hasAccessPermission('salary-report')) ? `/salary-report` : '/incentive/pending/incentive_payment'
				history(url2redirct)
			} else {
				setReloadCaptcha((prev) => prev + 1)
				if (btnRefs.current) btnRefs.current.removeAttribute("disabled");
			}
		}
	};

	const otpSend = async () => {
		if (validForm("mobile")) {
			if (btnRefs.current) btnRefs.current.setAttribute("disabled", "disabled");
			let response = await PaymentService.userOtpSend({
				mobile: inputField.mobile,
				password: inputField.password
			});
			if (btnRefs.current) btnRefs.current.removeAttribute("disabled");
			if (response && response.status === 200) {
				setFlag(false);
				setResend(false);
				setCounter(time);
				toast.success(`OTP Sent Successfully`);
				var interval = setInterval(() => {
					setCounter((time) =>
						time > 0 ? time - 1 : clearInterval(interval)
					);
				}, 1000);
			}
			setReloadCaptcha((prev) => prev + 1)
		}
	};

	const captchaFiledHandler = (childData) => {
		//setFields(childData);
		setInputField(childData);
		if (captchaData.captchaValue) {
			setInputField({ ...inputField, captcha: childData, hash: captchaData.captchaValue })
		}
	}

	return (
		<div className="login-form">
			<form autoComplete="off">
				{otpFlag ? (
					<React.Fragment>
						<fieldset className="">
							<div
								className={
									"material " +
									(errField.mobileErr.length > 0
										? "error"
										: "")
								}
							>
								<input
									className="form-input"
									type="text"
									placeholder=" "
									name="mobile"
									value={inputField.mobile}
									onChange={inputHandler}
								/>
								<i className="ic-mobile icn-login"></i>
								<label
									data-label="Mobile No."
									className="form-label"
								></label>
								{errField.mobileErr.length > 0 && (
									<span className="erroe-txt">
										{errField.mobileErr}
									</span>
								)}
							</div>
						</fieldset>
						<fieldset className="">
							<div
								className={
									"material " +
									(errField.passwordErr.length > 0 ? "error" : "")
								}
							>
								<input
									type="password"
									placeholder=" "
									name="password"
									value={inputField.password}
									className="form-input"
									onChange={inputHandler}
								/>
								<i className="ic-password icn-login"></i>
								<label
									data-label="Password"
									className="form-label"
								></label>
								{errField.passwordErr.length > 0 && (
									<span className="erroe-txt">
										{errField.passwordErr}
									</span>
								)}
							</div>
						</fieldset>
						<button
							type="button"
							className="btn-primary"
							onClick={otpSend}
							ref={btnRefs}
						>
							Login
						</button>
					</React.Fragment>
				) : (
					<React.Fragment>
						<fieldset className="">
							<div className="material">
								<input
									className="form-input"
									type="text"
									placeholder=" "
									name="otp"
									value={inputField.otp}
									onChange={inputHandler}
								/>
								<i className="ic-otp icn-login">
									<i className="path1"></i>
									<i className="path2"></i>
									<i className="path3"></i>
									<i className="path4"></i>
								</i>
								<label
									data-label="OTP"
									className="form-label"
								></label>
								{resendOtp ? null : (
									<span className="otp-time">
										00:{counter}
									</span>
								)}
								<div
									className="otp-success-icon"
									style={{ display: "none" }}
								>
									<i className="ic-check"></i>
									{errField.otpErr.length > 0 && (
										<span className="erroe-txt">
											{errField.otpErr}
										</span>
									)}
								</div>
							</div>
						</fieldset>
						{captchaData.is_radis === false ?
							<fieldset className=""  style={{ display: "none"}}>
								<Captcha onKeyPressCaptcha={ captchaFiledHandler } errField={errField} inputField={inputField} reloadCaptcha={reloadCaptcha}/>
							</fieldset> : 
							<fieldset className="" >
							<Captcha onKeyPressCaptcha={ captchaFiledHandler } errField={errField} inputField={inputField} reloadCaptcha={reloadCaptcha}/>
							</fieldset>
							}
						{resendOtp === true ? (
							<div className="btn-submit-resend">
								<button
									type="button"
									className="btn-primary"
									onClick={submitButton}
								>
									Submit
								</button>
								<button
									type="button"
									className="btn-line"
									onClick={otpSend}
								>
									Resend
								</button>
							</div>
						) : (
							<button
								type="button"
								className="btn-primary"
								onClick={submitButton}
							>
								Submit
							</button>
						)}
					</React.Fragment>
				)}
			</form>
		</div>
	);
}

export default MobileUser;
