import React, { useEffect, useState } from 'react';
import SimpleSlider from "./DocumentSlider"
import { useTranslation } from "react-i18next";
import Modal from "../elements/Modal"
import UpdatePayment from "./UpdatePayment"
import InfiniteScroll from 'react-infinite-scroll-component';
import Select from 'react-select';
import { numberFormatWithDots, displayDate } from "../common/Helper";
import { BANK_LIST, INCENTIVE_TYPE } from "../../config/constant";
import secureStorage from "../../config/encrypt";
import Loader from '../elements/Loader';
import StatusHistory from './StatusHistory';
import { PaymentService } from '../../service';
import moment from 'moment';


const IncentiveTable = (props) => {
    const { t } = useTranslation("language");
    const [reportData, setReportData] = useState(props.reportData)
    const [historyList, setHistoryList] = useState([]);
    const [historyLoading, setHistoryLoading] = useState(false)
    const [popupType, setPopupType] = useState('');
    const [popupData, setPopupData] = useState({});
    const [isCheckedCreateChkBox, setIsCheckedCreateChkBox] = useState(false);

    const currentDateTime = moment().toISOString();
    const currentDateTimeInTimeStamp = moment(currentDateTime, 'YYYY-MM-DD HH:mm:ss').unix();
    // {
    //     console.log(moment(current_obj.send_to_fincare_date, 'YYYY-MM-DD HH:mm:ss').unix(),"first")
    //     }
    //     {
    //     console.log(current_obj.send_to_fincare_date,"---",moment().toISOString())
    //     }

    
    /** Get Logedin user data */
    const loggedInUser = secureStorage.getItem("authUserInfo");
    const user_id = (loggedInUser.user_data && loggedInUser.user_data.user_id) || 0;
    const role_id = (loggedInUser.user_data && loggedInUser.user_data.role_id) || 0;
    const logged_id_user_role = role_id ? (role_id.includes('2') ? 'approver' : role_id.includes('1') ? 'maker' : 'verifier') : '';

    useEffect(() => {
        setReportData([...props.reportData]);
    }, [props.reportData])

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        if(props.showConfirmationPopup){
            togglePopup('VIEW_CONFIRMATION', props.data2SendManually)
        }else togglePopup('', {})
    }, [props.loading,props.showConfirmationPopup])

    const togglePopup = async (popupType, data) => {
        if (popupType === 'UPDATE_PAYMENT') {
            data.amount_to_be_paid = data.payment_amount
        } else if (popupType === 'STATUS_HISTORY') {
            await getStatusHistory({ user_incentive_id: data.incentive_id })
        }
        else if (popupType === 'VIEW_CONFIRMATION') {
           // setSelectedData([data])
        }
        setPopupData(data)
        setPopupType(popupType);
        if (popupType) {
            document.body.classList.add("overflow-hidden");
        }
        else {
            props.updateStateForManuallySendData({},true)
            document.body.classList.remove("overflow-hidden");
        }
    }

    const paymentUpdated = () => {
        props.paymentUpdated(true);
        togglePopup('', {})
    }

    const syncBankInfo = async (incentiveData) => {
        props.syncBankInfo(incentiveData)
    }

    const getStatusHistory = async (params) => {
        setHistoryLoading(true)
        let response = await PaymentService.getStatusHistory(params);
        if (response && response.status === 200 && response.data.status === 200 && response.data.data) {
            setHistoryList(response.data.data || []);
        }
        setHistoryLoading(false)

    }

    const sendInvoiceToFincare = (type,data2Send) => {
        if(type === 'auto'){
            let isConfirm = window.confirm("Are you sure want to send this invoice to fincare?");
            if(!isConfirm) return false;
        }
        props.sendInvoiceToFincare(data2Send, type)
    }

    const showConfirmation = (data) => {
        props.updateStateForManuallySendData([data]); 
        togglePopup('VIEW_CONFIRMATION', {});
    }
    return (
        <div id="scrollableDiv" >
            <InfiniteScroll
                dataLength={props.reportData.length}
                next={props.loadMoreItems}
                hasMore={props.hasMoreItems || false}
            // loader={props.loading || props.isFilter ? <div className="loader loader-default is-active loader-main" data-text="loading"></div> : null}
            >
                <div className={props.activeTab === 'pending' ? "payment-pending-table incentive-pending-table" : "payment-pending-table incentive-paid-table"}>
                    <div className='table-scroll-data'>
                        <table className='incentive-layout-table'>
                            <thead>
                                <tr>
                                    {props.activeTab === 'pending' && logged_id_user_role === 'verifier' ?
                                        <th>
                                            <div className="custom-control custom-checkbox">
                                                <input
                                                    id="chk_all"
                                                    name="chk_all"
                                                    type="checkbox"
                                                    value={props.selectedData.length !== 0 ? 'true' : 'false'}
                                                    checked={(props.sendToFincareData.length === props.selectedData.length && props.selectedData.length!==0)}
                                                    className="custom-control-input"
                                                    onChange={(e)=>props.handleChangeChkBox(e,{},'all')}
                                                />
                                                <label for="chk_all" className="custom-control-label"></label>
                                            </div>
                                        </th>
                                        : null
                                    }
                                    <th>{t("INCENTIVE_PAYMENT.SFA_ID")}</th>
                                    <th>{t("INCENTIVE_PAYMENT.AP_REFERENCE_ID")}</th>
                                    <th>{t("INCENTIVE_PAYMENT.USER_NAME")}</th>
                                    <th>{t("INCENTIVE_PAYMENT.USER_ROLE")}</th>
                                    <th>{t("INCENTIVE_PAYMENT.NPWP_NUMBER")}</th>
                                    {props.activeTab === 'pending' ?
                                        <th>{t("INCENTIVE_PAYMENT.AMOUNT_TO_BE_PAID")}</th>
                                        :
                                        <th>{t("INCENTIVE_PAYMENT.AMOUNT_PAID")}</th>
                                    }
                                    <th>{t("INCENTIVE_PAYMENT.BANK_NAME")}</th>
                                    <th>{t("INCENTIVE_PAYMENT.BRANCH_NAME")}</th>
                                    <th>{t("INCENTIVE_PAYMENT.ACCOUNT_NAME")}</th>
                                    <th>{t("INCENTIVE_PAYMENT.BENEFICIARY_NAME")}</th>
                                    <th>{t("INCENTIVE_PAYMENT.INCENTIVE_TYPE")}</th>
                                    <th>{t("INCENTIVE_PAYMENT.INCENTIVE_PERIOD")}</th>



                                    <th>{t("INCENTIVE_PAYMENT.RELATED_DOCUMENTS")}</th>
                                    {props.activeTab === 'pending' ?
                                        <th>{t("INCENTIVE_PAYMENT.ASSIGNED_TO")}</th>
                                        :
                                        <>
                                            <th>{t("INCENTIVE_PAYMENT.OTO_BANK")}</th>
                                            <th>{t("INCENTIVE_PAYMENT.PAYMENT_PAID_DATE")}</th>
                                            <th>{t("INCENTIVE_PAYMENT.UPDATED_DATE")}</th>
                                            <th>{t("INCENTIVE_PAYMENT.UTR_NUMBER")}</th>
                                        </>}
                                    <th>{t("INCENTIVE_PAYMENT.VERIFIER")}</th>
                                    <th>{t("INCENTIVE_PAYMENT.MAKER")}</th>
                                    <th>{t("INCENTIVE_PAYMENT.APPROVER")}</th>

                                    {props.activeTab === 'pending' ?
                                        <th>{t("INCENTIVE_PAYMENT.ACTION")}</th>
                                        : null
                                    }
                                    <th>{t("INCENTIVE_PAYMENT.STATUS")}</th>
                                    <th>{t("INCENTIVE_PAYMENT.FINCARE_STATUS")}</th>
                                    <th>{t("INCENTIVE_PAYMENT.HISTORY")}</th>

                                </tr>
                            </thead>
                            <tbody>
                                {reportData && reportData.length ? (
                                    reportData.map((current_obj, index) => (
                                        <React.Fragment
                                            key={
                                                current_obj.incentive_id + "_incentive_id"
                                            }
                                        >
                                            <tr key={
                                                current_obj.incentive_id + '-' + index
                                            }>
                                                {
                                                    props.activeTab === 'pending' && (![1,2].includes(+current_obj.invoice_status) && logged_id_user_role === 'verifier' && +current_obj.verifier_id === +user_id)?
                                                    <td>
                                                        {(currentDateTimeInTimeStamp <= moment(current_obj.send_to_fincare_date, 'YYYY-MM-DD HH:mm:ss').unix()) ?
                                                        <div className="custom-control custom-checkbox">
                                                            <input disabled
                                                                className="custom-control-input"
                                                                title='Send to Fincare After 10 minutes'
                                                                onChange={(e)=>props.handleChangeChkBox(e,current_obj,'single')}
                                                            />
                                                            <label for={"chk_"+current_obj.incentive_id} title='Send to Fincare After 10 minutes' className="custom-control-label"></label>                                                        
                                                        </div> :
                                                        <div className="custom-control custom-checkbox">
                                                        <input
                                                            id={"chk_"+current_obj.incentive_id}
                                                            name={"chk_"+current_obj.incentive_id}
                                                            type="checkbox"
                                                            checked={props.selectedData.filter(f=>f.incentive_id === current_obj.incentive_id).length}
                                                            className="custom-control-input"
                                                            onChange={(e)=>props.handleChangeChkBox(e,current_obj,'single')}
                                                        />
                                                        <label for={"chk_"+current_obj.incentive_id} className="custom-control-label"></label>                                                        
                                                    </div>
                                                    }
                                                    </td>
                                                    : props.activeTab === 'pending' && logged_id_user_role === 'verifier' ? <td></td>:null
                                                }
                                                <td>{current_obj.sfa_user_id || ''}</td>
                                                <td>{current_obj.ap_reference_id || ''}</td>
                                                <td><span className='line-clamp line-clamp-2' title={current_obj.sfa_user_name || ""}>{current_obj.sfa_user_name || ''}</span></td>
                                                <td>{current_obj.sfa_user_role || ''}</td>
                                                <td><span className='line-clamp line-clamp-2' title={current_obj.npwp_number || ""}>{current_obj.npwp_number ? String(current_obj.npwp_number).replace(/(\d{2})(\d{3})(\d{3})(\d{1})(\d{3})(\d{3})/, '$1.$2.$3.$4-$5.$6') : ''}</span></td>
                                                <td>{numberFormatWithDots(current_obj.payment_amount || 0) || ''}</td>
                                                <td> <h5 ><span className='line-clamp line-clamp-2' title={current_obj.bank_name || ""}>{current_obj.bank_name || ''}</span></h5></td>
                                                <td><span className='line-clamp line-clamp-2' title={current_obj.branch_name || ""}>{current_obj.branch_name || ''}</span></td>
                                                <td><span className='line-clamp line-clamp-2' title={current_obj.account_number || ""}>{current_obj.account_number || ''}</span></td>
                                                <td><span className='line-clamp line-clamp-2' title={current_obj.beneficiary_name || ""}>{current_obj.beneficiary_name || ''}</span></td>
                                                <td>{current_obj.incentive_type && INCENTIVE_TYPE.length ?
                                                    INCENTIVE_TYPE.filter(f => f.value === current_obj.incentive_type)
                                                        .map(e => {

                                                            return (<h5 key={e.value}>{e.label || ''}</h5>)
                                                        }) : ""}
                                                </td>
                                                <td>{current_obj.incentive_period || ''}</td>

                                                <td><span onClick={() => togglePopup('VIEW_DOC', current_obj)} className="link-txt">{t("INCENTIVE_PAYMENT.VIEW")}</span></td>
                                                {props.activeTab === 'pending' ?
                                                    <td>
                                                        <div className='inbound-financier-select'>

                                                            <Select
                                                                options={([1, 3].includes(current_obj.status_id)) ? props.users.filter(data => data.user_roles.includes('4')) : ([2].includes(current_obj.status_id)) ? props.users.filter(data => data.user_roles.includes('1')) : ([4].includes(current_obj.status_id)) ? props.users.filter(data => data.user_roles.includes('2')) : props.users}
                                                                getOptionLabel={({ diplayname }) => diplayname}
                                                                getOptionValue={({ id }) => id}
                                                                className="react-select"
                                                                classNamePrefix="react-select"
                                                                placeholder={t("INCENTIVE_PAYMENT.ASSIGNED_TO")}
                                                                value={current_obj.assigned_to ? props.users && props.users.filter(({ id }) => {
                                                                    let assigned_id = current_obj.assigned_to === "verifier" ? current_obj.verifier_id : (current_obj.assigned_to === "maker" ? current_obj.maker_id : current_obj.approver_id);
                                                                    return (id === assigned_id);
                                                                }) : ""}
                                                                onChange={props.updateAssignedUser.bind(this, current_obj.incentive_id, current_obj.payment_source)}
                                                                isDisabled={logged_id_user_role === 'approver' && !current_obj.approver_id ? false : true}
                                                            />

                                                        </div>

                                                    </td>
                                                    :
                                                    <>
                                                        <td>{current_obj.oto_bank && BANK_LIST.length ?
                                                            BANK_LIST.filter(f => f.value === current_obj.oto_bank)
                                                                .map(e => {

                                                                    return (<React.Fragment>
                                                                        <span>{e.label}</span>
                                                                        <span>{e.outbound_account_number || ""}</span>
                                                                    </React.Fragment>)
                                                                }) : ""}
                                                        </td>
                                                        <td>{displayDate(current_obj.payment_date)}</td>
                                                        <td>{displayDate(current_obj.updated_date)}</td>
                                                        <td><span className='line-clamp line-clamp-2' title={current_obj.utr_number || ""}>{current_obj.utr_number || ""}</span></td>


                                                    </>}
                                                <td>{(props.users && props.users.filter(data => data.id === current_obj.verifier_id).map(v => v.name)[0]) || ""}</td>
                                                <td>{(props.users && props.users.filter(data => data.id === current_obj.maker_id).map(v => v.name)[0]) || ""}</td>
                                                <td>{(props.users && props.users.filter(data => data.id === current_obj.approver_id).map(v => v.name)[0]) || ""}</td>

                                                {
                                                    (props.activeTab === 'pending') ?
                                                        <React.Fragment>
                                                            {(logged_id_user_role === 'approver') ?
                                                                (+current_obj.status_id === 4 && +current_obj.approver_id === +user_id && +current_obj.fincare_status !== 8) ?
                                                                    <td onClick={+current_obj.status_id === 4 ? togglePopup.bind(this, 'UPDATE_PAYMENT', { ...current_obj, user_incentive_id: current_obj.incentive_id }) : null}>
                                                                        <span className={+current_obj.status_id === 4 ? "link-txt" : ""}>{t("INCENTIVE_PAYMENT.UPDATE")}</span>
                                                                    </td>
                                                                    :
                                                                    <td>
                                                                        <span>{t("INCENTIVE_PAYMENT.UPDATE")}</span>
                                                                    </td>
                                                                :
                                                                (logged_id_user_role === 'maker') ?
                                                                    <td>
                                                                        {+current_obj.status_id === 4 ? <span>{t("INCENTIVE_PAYMENT.ENTRY_CREATED")}</span> :
                                                                            (+current_obj.status_id === 2 && +current_obj.maker_id === +user_id) ?
                                                                                <React.Fragment>
                                                                                    <div className='d-flex'>
                                                                                        <span className="link-txt" onClick={() => props.verifyIncentivePayment({ user_incentive_id: current_obj.incentive_id, payment_source: current_obj.payment_source, action_type: 'create', maker_id: current_obj.maker_id, verifier_id: current_obj.verifier_id })}>Create</span>
                                                                                        <span className="link-txt m-sm-l" onClick={() => props.verifyIncentivePayment({ user_incentive_id: current_obj.incentive_id, payment_source: current_obj.payment_source, action_type: 'reject' })}>Reject</span>
                                                                                        {/* <span className="link-txt m-sm-l" onClick={() => syncBankInfo({ user_incentive_id: current_obj.incentive_id, sfa_user_id: current_obj.sfa_user_id })} >Sync</span> */}
                                                                                    </div>
                                                                                </React.Fragment> :
                                                                                <span>{t("INCENTIVE_PAYMENT.CREATE")}</span>}
                                                                    </td>
                                                                    :
                                                                    (logged_id_user_role === 'verifier') ?
                                                                        (current_obj.send_to_fincare_attempt < 5) ?

                                                                            (currentDateTimeInTimeStamp <= moment(current_obj.send_to_fincare_date, 'YYYY-MM-DD HH:mm:ss').unix()) && current_obj.invoice_status!=='1' ?
                                                                                <td className='d-flex'>
                                                                                    <span title='Send to Fincare After 10 minutes' className={[0, 1, 3].includes(+current_obj.status_id) && +current_obj.verifier_id === +user_id ? "" : ""}>{t("INCENTIVE_PAYMENT.SEND")}</span>
                                                                                    <span className="link-txt m-sm-l" onClick={() => syncBankInfo({ user_incentive_id: current_obj.incentive_id, sfa_user_id: current_obj.sfa_user_id })} >Sync</span>

                                                                                </td>
                                                                                : 
                                                                                <td className='d-flex'>
                                                                                    <span onClick={() => [0, 1, 3].includes(+current_obj.status_id) && +current_obj.verifier_id === +user_id ? sendInvoiceToFincare('auto',[current_obj]) : null} className={[0, 1, 3].includes(+current_obj.status_id) && +current_obj.verifier_id === +user_id ? "link-txt" : "display-none-force"}>{t("INCENTIVE_PAYMENT.SEND")}</span>
                                                                                    <span className={[0, 1, 3].includes(+current_obj.status_id) && +current_obj.verifier_id === +user_id ? "link-txt m-sm-l" : "display-none-force"} onClick={() => syncBankInfo({ user_incentive_id: current_obj.incentive_id, sfa_user_id: current_obj.sfa_user_id })} >Sync</span>

                                                                                </td>
                                                                            :
                                                                            (currentDateTimeInTimeStamp <= moment(current_obj.send_to_fincare_date, 'YYYY-MM-DD HH:mm:ss').unix()) ?
                                                                            <td>
                                                                                <span title='Send to Fincare After 10 minutes' className={[0, 1, 3].includes(+current_obj.status_id) && +current_obj.verifier_id === +user_id ? "" : "display-none-force"}>{t("INCENTIVE_PAYMENT.CREATE")}</span>
                                                                            </td>
                                                                            :
                                                                            <td onClick={() => [0, 1, 3].includes(+current_obj.status_id) && +current_obj.verifier_id === +user_id ? showConfirmation(current_obj) : null}>
                                                                                <span className={[0, 1, 3].includes(+current_obj.status_id) && +current_obj.verifier_id === +user_id ? "link-txt" : "display-none-force"}>{t("INCENTIVE_PAYMENT.CREATE")}</span>
                                                                            </td>
                                                                        : null
                                                            }

                                                        </React.Fragment>
                                                        : null
                                                }
                                                <td>{(props.INVOICE_STATUS && props.INVOICE_STATUS.filter(data => +data.value === +current_obj.invoice_status).map(v => v.label)[0]) || ""}</td>
                                                <td>{props.FINCARE_STATUS && props.FINCARE_STATUS
                                                    .filter(data => +data.value === +current_obj.fincare_status)
                                                    .map(v => v.label + ' || ' + v.subStatus)[0]
                                                    }
                                                </td>
                                                <td><span onClick={() => togglePopup('STATUS_HISTORY', current_obj)} className="link-txt">{t("INCENTIVE_PAYMENT.VIEW")}</span></td>


                                            </tr>


                                        </React.Fragment>
                                    ))
                                ) : ((!props.loading) ?
                                    <tr key="no_record">
                                        <td colSpan="12">
                                            {!false ? (
                                                <h1 className="text-danger">
                                                    {t("COMMON.NOT_FOUND")}
                                                </h1>
                                            ) : (
                                                ""
                                            )}
                                        </td>
                                    </tr> : null
                                )}


                            </tbody>
                        </table>
                    </div>
                    {props.loading || props.isFilter || historyLoading ? <Loader /> : null}
                    <div className="update-payment-popup no-scrollable-popup">
                        <Modal show={popupType === 'UPDATE_PAYMENT'} handleClose={() => togglePopup('', {})} >
                            <div className="modal-header">
                                <h2>{t("INCENTIVE_PAYMENT.UPDATE_PAYMENT.TITLE")}</h2>
                            </div>
                            <UpdatePayment paymentData={popupData} toggleUpdatePayment={paymentUpdated} payment_source={props.payment_source} />
                        </Modal>
                    </div>
                    <div className={"update-payment-popup document-view-popup " + ((popupType === 'VIEW_DOC') ? 'document-verfication-process' : '')}>
                        <Modal show={popupType === 'VIEW_DOC'} handleClose={() => togglePopup('', {})} >
                            <SimpleSlider incentiveDetails={popupData} />
                        </Modal>
                    </div>
                  
                </div>
                
            </InfiniteScroll>
            <div className={"view-timeline-popup image-timeline-popup"}>
                        <Modal show={popupType === 'STATUS_HISTORY'} handleClose={() => togglePopup('', {})} >

                            <StatusHistory historyList={historyList} isLoading={historyLoading} />

                        </Modal>
                    </div>
                    <div className={"view-timeline-popup image-timeline-popup"}>
                        <Modal show={popupType === 'VIEW_CONFIRMATION'} handleClose={() => togglePopup('', {})} >


                            <div className="modal-header">
                                <h2>{t("INCENTIVE_PAYMENT.CONFIRMATION_TITLE")}</h2>
                            </div>
                            <div className="modal-body" >
                                <div className="row">

                                    <fieldset className="form-filed col-md-12">
                                        <div className="material">
                                            <div className="custom-control custom-checkbox">
                                                <input
                                                    id="conf_chk"
                                                    name="conf_chk"
                                                    type="checkbox"
                                                    value="true"
                                                    checked={isCheckedCreateChkBox}
                                                    className="custom-control-input"
                                                    onChange={(e)=>{setIsCheckedCreateChkBox(e.target.checked)}}
                                                />
                                                <label for="conf_chk" className="custom-control-label">I confirm that I have uploaded the Invoice copies of <b>{props.data2SendManually.length || 1 }</b> Incentive Tranches on Fincare Portal and want to procced ahead for the payment</label>
                                            </div>
                                        </div>
                                    </fieldset>
                                    <fieldset className="form-filed col-md-12">
                                        <div className="col-md-6">
                                            <button className="btn-primary" onClick={() => sendInvoiceToFincare('manual',props.data2SendManually)} disabled={!isCheckedCreateChkBox}>{t("INCENTIVE_PAYMENT.PROCEED")}</button>
                                        </div>
                                        <div className="col-md-6">
                                            <button className="btn-danger" onClick={() => togglePopup('', {})} >{t("INCENTIVE_PAYMENT.CANCEL")}</button>
                                        </div>
                                    </fieldset>

                                </div>

                            </div>

                        </Modal>
                    </div>
        </div>

    )

}
export default IncentiveTable;