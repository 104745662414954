import React, { createRef } from "react";
import csvsample from "../../webroot/csv-sample/salaryReport.csv";
import { toast } from "react-toastify";
import { CSVLink } from "react-csv";
import PaymentService from "../../service/paymentService";

class FileUploader extends React.Component {
    constructor(props) {
		super(props);
		this.image = createRef();
		this.handleUploadImage = this.handleUploadImage.bind(this);
		this.handleDragOver = this.handleDragOver.bind(this);
		this.handleDragEnter = this.handleDragEnter.bind(this);
		this.handleDragLeave = this.handleDragLeave.bind(this);
		this.handleDrop = this.handleDrop.bind(this);
		this.state = {
			user_id: (this.loggedInUser && this.loggedInUser.user_data) ? this.loggedInUser.user_data.user_id : '',
			selectedFileName: "",
			errorNotification: "",
			resetFileUpload: "",
			errorText: "",
			filename: "",
			file: "",
			uploadSuccess: false,
			dragOver: false,
			loading: false,
			responseData: {},
			formData: {},
			postData: {},
			invalid_data: [],
			
		};
	}

	static getDerivedStateFromProps(props, state) {
		const updateProps = {};
		updateProps["payment_update_type"] = props.payment_update_type || 'dealer';
		updateProps["other_payment"] = props.other_payment || 'N';
		if (props.resetFileUpload && props.resetFileUpload !== state.resetFileUpload) {
			updateProps["resetFileUpload"] = props.resetFileUpload;
			updateProps["filename"] = "";
			updateProps["errorText"] = "";
			updateProps["uploadSuccess"] = false;
			updateProps["errorNotification"] = "";
			updateProps["loading"] = false;
		}
		return updateProps;
	}

	/**
	   Drag and Drop Event Handlers
	**/
	handleDragEnter(e) {
		e.preventDefault();
	}

	handleDragOver(e) {
		e.preventDefault();
		let { dragOver } = this.state;
		if (!dragOver) {
			this.setState({
				dragOver: true,
			});
		}
	}

	handleDragLeave(e) {
		e.preventDefault();
		this.setState({
			dragOver: false,
		});
	}

	/**
	   Handle Upload after Upload Button Clicked
	**/
	handleUploadImage = () => {
		this.setState({ filename: "" });
	};

	handleDrop(event) {
		event.preventDefault();
		// let { postData } = this.state;
		let file = event.dataTransfer.files[0];
		// Validate file is of type Image
		let fileType = file.type.split(".")[0];
		if (fileType !== "text/csv") {
			this.setState({
				file: "",
				errorNotification: "Not a CSV file",
				dragOver: false,
			});
			return setTimeout(() => {
				this.setState({
					errorNotification: null,
				});
			}, 3000);
		}
		let formData = new FormData();
		formData.append("salary_csv", event.target.files[0]);
		this.updatePayment(formData);
		this.setState({
			file,
			filename: file.name,
			dragOver: false,
		});
	}

	handleChangleDoc = async (event) => {
		let files = Array.from(event.target.files);
		if (files && files.length) {
			let filename = files[0].name || "";
			let formData = new FormData();
			formData.append("salary_csv", event.target.files[0]);
			this.setState({ filename: filename });
			await this.updatePayment(formData);
		}
	};

	updatePayment = async (formData) => {
		this.setState({ loading: true });
		let { uploadSuccess, responseData, filename, errorText, invalid_data } = this.state;
		await PaymentService.uploadSalaryReport(formData)
        .then((response) => {
			this.setState({ loading: false });
			if (response.status === 200 && response.data.status === 200) {
				this.props.onFileUpload({ status: true });
				responseData = response.data && response.data.data;
				uploadSuccess = true;
				filename = "File has been uploaded successfully";
				if (responseData && responseData.invalid_data.length) {
					invalid_data = responseData.invalid_data;
					errorText = `Only ${responseData.invalid_data.length} listing are pending to upload due to data error`;
				}
				toast.success(response.data.message);
			} else {
				//uploadResponseData = (response.data.data && response.data.data.errors) ? response.data.data.errors : [];
			}
			this.setState({
				responseData: responseData,
				uploadSuccess: uploadSuccess,
				filename: filename,
				errorText: errorText,
				invalid_data: invalid_data,
			});
		});
	};

	render() {
		let {
			invalid_data,
			dragOver,
			filename,
			errorText,
			errorNotification,
			uploadSuccess,
			loading
		} = this.state;
		// Match drag over css to hover css
		let dragOverClass = dragOver ? `display-box drag-over` : `display-box`;

		// If file is set, change upload box text to file name
		let uploadText = filename ? (
			<div className="upload-file-name">
				<h4>
					{filename} <i className="ic-clearclose1"></i>{" "}
				</h4>
				<button className="btn-primary">
                    Browse Another File
				</button>
			</div>
		) : (
			<div className="upload-txt">
				<h4>
					{" "}
					Drop Files Here to Upload
				</h4>
				<span>
                Only .csv files are accepted and size should not be more then 1 GB
				</span>
				<div className="or-txt"> or </div>
				<button className="btn-primary file-btn">
					{" "}
					Browse File{" "}
				</button>
			</div>
		);

		let successText = <h4>{filename}</h4>;

		// Show Error message if file type is not an image
		errorNotification = errorNotification ? (
			<div className="error-notification">
				<p>{errorNotification}</p>
			</div>
		) : (
			""
		);

		let errorMessgae = errorText ? (
			<div className="upload-error-msg">
				<span>{errorText}</span>
				<CSVLink data={invalid_data} filename="salaryReportInvalidDataList.csv">
					<span>
						Download Invalid Data file{" "}
					</span>
					<i className="ic-save_alt"></i>
				</CSVLink>
				;
			</div>
		) : (
			""
		);

		let inputVar = (
			<input
				type="file"
				ref={this.image}
				id="upload-image-input"
				className={
					this.state.uploadSuccess
						? "upload-box"
						: " upload-image-input"
				}
				accept=".csv,.CSV"
				onDrop={this.handleDrop}
				onDragEnter={this.handleDragEnter}
				onDragOver={this.handleDragOver}
				onDragLeave={this.handleDragLeave}
				onChange={this.handleChangleDoc}
				disabled={loading}
			/>
		);

		return (
        <div>  
        <div className="modal-body">  
			<div style={{ textAlign: "center" }}>
				<div className={loading ? `loader-default`: ''}>
					<div
						className={
							uploadSuccess
								? "payment-upload-succss"
								: "image-uploader-wrapper"
						}
					>
						<div className={dragOverClass}>
							<div className="icon-text-box">
								<div
									className={
										uploadSuccess
											? "success-icn"
											: "upload-icon"
									}
								>
									<i
										className={
											uploadSuccess
												? "ic-check"
												: "ic-file-icon"
										}
										aria-hidden="true"
									/>
								</div>
								{!uploadSuccess ? (
									<div className="upload-text">
										{uploadText}
									</div>
								) : (
									successText
								)}
								{errorMessgae}
								{errorNotification}
							</div>

							{uploadSuccess ? (
								<div className="upload-btn-txt">
									<span>
										Upload Another File
									</span>
									<div className="button-wrapper" disabled={true}>
										<span className="label">
											Browse File
										</span>
										{inputVar}
									</div>
								</div>
							) : (
								<div>{inputVar}</div>
							)}
						</div>
					</div>
				</div>
			</div>
        </div>  
        <div class="download-txt">
        Download input format
        <a style={{ paddingLeft : '10px'}} href={csvsample} download=""> Click Here  </a>
        </div>
        </div>  
		);
	}
}

export default FileUploader;



