import React, { useState } from 'react';
import { Tab, Nav } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import UserTab from "./user/UserTab";
import RoleList from "./role/RoleList";
function UserRoleManagement() {
    // let history = useHistory();
    const [slctTab, setSlctTab] = useState("user");
    const { t } = useTranslation('language');
    const handleSelectTab = (eventKey) => {
        setSlctTab(eventKey);
    }
    return (
        <div className="container">
            <div className="top-heading"><h1>{slctTab === 'user' ? t("USER_ROLE.USER_MANAGEMENT") : t("USER_ROLE.ROLE_MANAGEMENT")}</h1></div>
            <div className="tab-chips">
                <Tab.Container defaultActiveKey={slctTab} onSelect={handleSelectTab}>
                    <div className="tab-chip-outer">
                        <Nav variant="pills" className="flex-column">
                            <Nav.Item>
                                <Nav.Link active={slctTab === 'user'} eventKey="user">{t("USER_ROLE.USER_MANAGEMENT")}</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link active={slctTab === 'role'} eventKey="role">{t("USER_ROLE.ROLE_MANAGEMENT")}</Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </div>
                    <div>
                        <Tab.Content>
                            <Tab.Pane active={slctTab === 'user'} eventKey="user">
                                <UserTab t={t} activeTab={slctTab} />
                            </Tab.Pane>
                            <Tab.Pane active={slctTab === 'role'} eventKey="role">
                                <RoleList activeTab={slctTab} />
                            </Tab.Pane>
                        </Tab.Content>
                    </div>
                </Tab.Container >
            </div>
        </div>
    )
}
export default UserRoleManagement;