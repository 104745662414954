import React, { useState, useEffect } from 'react';
import { Tab, Nav } from 'react-bootstrap';
import Select from "react-select";
import dateFormat from "dateformat"
import { DATE_FORMAT_YMD, AMOUNT_FORMAT_TYPE,BANK_LIST_OBJECTS,INCENTIVE_TYPE } from "../../config/constant"
import { useTranslation } from "react-i18next";
import { PaymentService } from '../../service';
import { useParams } from 'react-router';
import SingleExcelExport from '../elements/SingleExcelExport';
import { toast } from 'react-toastify';
import { numberFormatWithLocale } from '../common/Helper';
import Modal from '../elements/Modal';
import IncentiveTable from "../incentive-payment/IncentiveTable";
import IncentiveSearch from "../incentive-payment/IncentiveSearch";
import FileUploader from "./FileUpload";
import secureStorage from "../../config/encrypt";


const initialPageObj = { "page": 1, "payment_status": 'pending', payment_source: 'incentive_payment' };
const defaultSearchObj = {
    pending: { incentive_payment:{} },
    paid: { incentive_payment:{} },
    Impportdatapopup: false,
}
const INVOICE_STATUS = [
    { value: '1', label: 'Success' },
    { value: '2', label: 'Manual Success' },
    { value: '3', label: 'Rejected' },
    { value: '4', label: 'Failed' },
];

const FINCARE_STATUS = [
    { value: '7', label: 'Open', subStatus: 'Pending'},
    { value: '8', label: 'Closed', subStatus: 'Payment Done'}
];

const IncentivePayment = () => {
    var date = new Date();
    // var firstDay = new Date(date.getFullYear(), date.getMonth() - 2, 1);
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    var refinance_firstDay =  new Date(date.getFullYear(), date.getMonth(), 1);
    defaultSearchObj['paid']['incentive_payment'] = { date_filter_type: "payment_paid", from_date: refinance_firstDay, to_date: lastDay };
    const { t } = useTranslation("language");
    const [initialPage, setInitialPage] = useState(initialPageObj);
    const [activeTab, setActivetab] = useState('incentive_payment');
    let [filterData, setFilterData] = useState({...defaultSearchObj})
    let [filterInfo, setFilterInfo] = useState({...defaultSearchObj});
    const [filtersArr, setFiltersArr] = useState({
        pending: { incentive_payment:[] },
        paid: { incentive_payment:[] },
    });
    const [reportData, setReportData] = useState([])
    const [isLoading, setIsLoading] = useState(true);
    const [hasMoreItems, setHasMoreItems] = useState(true);
    const [showFilter, setShowFilter] = useState(true);
    const [searchResultCount, setSearchReasultCount] = useState(0);
    const [totalTabCounts, setTotalTabCounts] = useState({total_incentive_payment:0, all_cases: 0, my_cases: 0 });
    const [downloadEnable, setDownloadEnable] = useState(false);
    const [isFilter, setIsFilter] = useState(false);
    const [errors, setError] = useState({});
    const [showDownloadModal, setShowDownloadModal] = useState(false);
    const [amountFormatType, setAmountFormatType] = useState('');
    let { tab: maintab, subtab } = useParams();
    const [exportFilterData, setExportFilterData] = useState(defaultSearchObj)
    const [activeUserTab, setActiveUserTab] = useState('my_cases');
    const [users, setUsers] = useState([]);
    const [showBulkUploadModal, setBulkUploadModal] = useState(false);
	const loggedInUser = secureStorage.getItem("authUserInfo");
    const loginUserData = loggedInUser.user_data.role_id.split(',');
    const [sendToFincareData, setSendToFincareData] = useState([]);
    const [selectedData, setSelectedData] = useState([]);
    const [data2SendManually, setData2SendManually] = useState([]);
    const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        if (maintab) {
            setSearchReasultCount(0);
            setReportData([]);
            setActivetab(subtab);
            submiFilter({}, subtab);
            getAllUserList('')
        }
    }, [maintab, subtab])

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        submiFilter({}, subtab);
    }, [])

    const changeHandler = async (name, value) => {
        let v = '', vi = '';
        if (value && value instanceof Array) {
            v = value.map(obj => obj.id || obj.value);
            vi = value.map(obj => { return { key: name, label: obj.name || obj.label, value: obj.id || obj.value } });
        } else {
            v = value;
            if (name === 'from_date') {
                filterData[maintab][activeTab].to_date = '';
            }
            if (name === 'loan_id') { value = filterData[maintab][activeTab].loan_id = Number(value.replace(/\D/g, '')); }
            if (value === 'payment_paid') {
                value = 'Payment Paid Date'
            }
            if (value === 'payment_updated') {
                value = 'Payment Updated Date'
            }
            vi = value;
        }
        await setFilterInfo((prev) => ({
            ...prev,
            [maintab]: {
                ...filterInfo[maintab],
                [activeTab]: {
                    ...filterInfo[maintab][activeTab],
                    [name]: vi,
                    ...(name === 'from_date' && { to_date: '' })
                }
            }
        }));
        await setFilterData((prev) => ({
            ...prev,
            [maintab]: {
                ...filterData[maintab],
                [activeTab]: {
                    ...filterData[maintab][activeTab],
                    [name]: v,
                    ...(name === 'from_date' && { to_date: '' })
                }
            }
        }));
    }

    const submiFilter = (e, subtab = activeTab) => {
        let arr = [];
        let filterInfoObj = filterInfo[maintab][subtab];
        for (const key in filterInfoObj) {
            if (Object.hasOwnProperty.call(filterInfoObj, key)) {
                let element = filterInfoObj[key] || [],labeltxt='';
                if (key === 'payment_request_type') {
                    labeltxt = 'Payment Type';
                }
                if (key === 'date_filter_type') {
                    labeltxt = 'Date Type';
                    if (element === "payment_paid") 
                        element = "Payment Paid Date";
                    else if (element === "payment_updated")
                        element = "Payment Updated Date";
                }
                if (key === 'from_date') {
                    labeltxt = 'Start Date';
                    element = dateFormat(element, DATE_FORMAT_YMD)
                }
                if (key === 'to_date') {
                    labeltxt = 'End Date';
                    element = element ? dateFormat(element, DATE_FORMAT_YMD) : ''
                }
                if (key === 'sfa_user') {
                    labeltxt = 'Agent Name, SFA ID';
                }
                if (key === 'user_role') {
                    labeltxt = 'User Role';
                }
                if (key === 'incentive_type') {
                    labeltxt = 'Incentive Type';
                    element = INCENTIVE_TYPE.filter(f=>f.value === element).map(e=>e.label)[0]
                }
                if (key === 'incentive_period') {
                    labeltxt = 'Incentive Period';
                    element = dateFormat(element, 'mmm yyyy')
                }
                if (key === 'invoice_status') {
                    labeltxt = 'Invoice Status';
                    element = INVOICE_STATUS.filter(f=>f.value === element).map(e=>e.label)[0]
                }
                if (element instanceof Array){
                     // eslint-disable-next-line array-callback-return
                     element.map((item) => {
                        arr.push({ labeltxt: labeltxt, label: item.label, value: item.value, key: key });
                    });
                }
                else arr.push({ labeltxt: labeltxt, label: element, value: element, key: key });

                if (element === '' || element.length === 0) {
                    delete filterInfo[key];
                    delete filterData[key];
                }
            }
        }
        setExportFilterData(filterInfo);
        filtersArr[maintab][subtab] = arr;
        setFiltersArr({ ...filtersArr });
        setIsFilter(true);
        setInitialPage({ "page": 1, "payment_status": maintab, payment_source: subtab });
        getReport({ ...filterData, ...{ "page": 1, "payment_status": maintab, payment_source: subtab } });
    }

    const resetFilter = (e, tab = maintab, subtab = activeTab) => {
        if (e === 'reset') {
            let fd = { ...filterData, [tab]: { ...filterData[tab], [subtab]: {} } }
            filterData = fd;
            filtersArr[maintab][activeTab] = [];
            let finfo = { ...filterInfo, [tab]: { ...filterInfo[tab], [subtab]: {} } }
            filterInfo = finfo;
        }
        setFilterData({ ...filterData });
        setFiltersArr({ ...filtersArr });
        setFilterInfo({ ...filterInfo });
        setShowFilter(true);
        setIsFilter(true);
        setExportFilterData({ ...filterData });
        setInitialPage({ "page": 1, "payment_status": tab, payment_source: subtab });
        getReport({ "page": 1, "payment_status": tab, payment_source: subtab, ...filterData });
    }

    const removeFilter = (value, key) => {
        if (['date_type', 'payment_request_type', 'date_filter_type', 'from_date', 'to_date','sfa_user','incentive_type','incentive_period','invoice_status'].includes(key)) {
            delete filterData[maintab][activeTab][key];
            delete filterInfo[maintab][activeTab][key];
            let indx = filtersArr[maintab][activeTab].findIndex(itm => itm.key === key);
            filtersArr[maintab][activeTab].splice(indx, 1);
        }
        else {
            filterData[key] = filterData[maintab][activeTab][key].filter(elmt => (elmt !== value));
            let indx = filtersArr[maintab][activeTab].findIndex(itm => (itm.key === key && itm.value === value));
            filtersArr[maintab][activeTab].splice(indx, 1);
            let indxInfo = filterInfo[maintab][activeTab][key].findIndex(itm => (itm.key === key && itm.value === value));
            filterInfo[maintab][activeTab][key].splice(indxInfo, 1);

        }
        setFilterData((prev) => ({ ...prev, ...filterData }));
        getReport({...filterData })
    }
    
    const getReport = async (searchObj) => {
        searchObj = { ...initialPage, request_type: activeUserTab, ...searchObj, ...searchObj[maintab] };
        delete searchObj.pending;
        delete searchObj.paid;
        setIsLoading(true);
        await PaymentService.getIncentivePayment(searchObj)
        .then(response => {
            if (response.status === 200 && response.data.status === 200) {
                let { data, pagination = {} } = response.data;
                let reportDataArr = (searchObj.page === 1) ? data : reportData.concat(data);
                let send2FincareList = [],loggedInUserId = loggedInUser.user_data.user_id || 0
                if (data.length) {
                    setReportData(reportDataArr);
                    // eslint-disable-next-line array-callback-return
                    reportDataArr.map(report_obj => {
                        if ([1, 3].includes(report_obj.status_id) && +report_obj.verifier_id === +loggedInUserId) {
                            send2FincareList.push(report_obj)
                        }
                    });
                    setSendToFincareData([...send2FincareList])
                } else if (searchObj.page === 1) {
                    setReportData([])
                }
                
                if (pagination) {
                    setHasMoreItems(pagination.next_page);
                    setSearchReasultCount(pagination.total)
                    setTotalTabCounts({ ...pagination.tab_counts });
                }
            }
            setIsLoading(false);
            setIsFilter(false);
        })
        
    }
    // const tabChangeHandler = (tabName) => {
    //     setActivetab(tabName);
    //     setInitialPage({ "page": 1, "payment_status": maintab, payment_source: activeTab });
    //     setShowFilter(true)
    //     history(`/incentive-payment/${maintab}/${tabName}`);
    // }

    const tabChangesUserwise = (tabName) => {
        setActiveUserTab(tabName);
        setIsLoading(true);
        setReportData([]);
        setInitialPage({...initialPage, request_type: tabName });
        setShowFilter(true)
        getReport({ ...filterData, ...{ "page": 1, "payment_status": maintab, payment_source: activeTab, request_type: tabName } });
    }

    const getAllUserList = async (userSearch) => {
        await PaymentService.getAllUserList(userSearch).then((response) => {
            if (response && response.status === 200 && response.data.status === 200) {
                let { data } = response.data;
                let users = [];
                // eslint-disable-next-line array-callback-return
                data && data.users.map((user) => {
                    let roles = user.user_roles && user.user_roles.split(',');
                    if (roles && roles.length && (roles.includes('1') || roles.includes('2') || roles.includes('4'))) {
                        user.diplayname = user.name //+ ((roles.includes('2')) ? '(Approver)': '(Maker)')
                        users.push(user);
                    }
                });
                setUsers(users);
            }
        });
    }

    const verifyIncentivePayment = async (updateData) => {
        let result = window.confirm(`Do you want to ${updateData.action_type} the request?`);
		if (result) {
        setIsLoading(true);
        await PaymentService.verifyIncentivePayment(updateData).then((response) => {
            if (response && response.status === 200) {
                 setInitialPage({ "page": 1, "payment_status": maintab, payment_source: activeTab });
                 getReport({ ...filterData, ...{ "page": 1, "payment_status": maintab, payment_source: activeTab } });
				 toast.success(response.data.message);
            }
            setIsLoading(false);
        });
       }
    }

    const updateAssignedUser = async (incentiveId,payment_source,event) => {
        let result = window.confirm("Are you sure want to update?");
		if (result) {
			let postData = {
				user_id: event.id,
				user_incentive_id: incentiveId,
                payment_source:payment_source
			};
            let response = await PaymentService.updateAssignedUser(postData);
			if (response.status === 200 && response.data.status === 200) {
                setInitialPage({ "page": 1, "payment_status": maintab, payment_source: activeTab });
                getReport({ ...filterData, ...{ "page": 1, "payment_status": maintab, payment_source: activeTab } });
				toast.success(response.data.message);
			}
		}
	};

    const loadMoreItems = () => {
        initialPage['page'] = 1 + initialPage['page'];
        setInitialPage({ ...initialPage })
        getReport({ ...filterData, page: initialPage['page'] })
    }
    /** paymentUpdated */
    const paymentUpdated = (status) => {
        getReport({ ...filterData, ...{ "page": 1, "payment_status": maintab, payment_source: activeTab } });
    }
    // let isFilterApplied = (filtersArr[maintab] && filtersArr[maintab][activeTab] && filtersArr[maintab][activeTab].length) ? true : false;
    const getCsvReportData = async () => {
        let searchObject  = {}
            searchObject = { ...exportFilterData[maintab] };
        if(maintab==='pending'){
            searchObject = { ...filterData[maintab] };
        }
        if (!amountFormatType) {
            setError({ 'amount_format_type': 'error' });
            toast.error('Select amount format type')
            return false;
        }
        setDownloadEnable(true);
        //if (searchObject && searchObject['incentive_payment'] && (searchObject['incentive_payment'].date_filter_type && searchObject['incentive_payment'].from_date && searchObject['incentive_payment'].to_date) || maintab==='pending') {
        if ((searchObject && searchObject['incentive_payment']) || (maintab === 'pending')) {
            delete searchObject['incentive_payment'].page;
            searchObject.payment_source = activeTab;
            searchObject.payment_status = maintab;
            if (searchObject[activeTab]["date_filter_type"] === 'Payment Paid Date') {
                searchObject[activeTab]["date_filter_type"] = 'payment_paid'
            } else if (searchObject[activeTab]["date_filter_type"] === 'Payment Updated Date') {
                searchObject[activeTab]["date_filter_type"] = 'payment_updated'
            }
            searchObject.request_type = activeUserTab;
            await PaymentService.getIncentivePayment(searchObject)
                .then(response => {
                    if (response.status === 200 && response.data && response.data.status === 200) {
                        let { data } = response.data;
                        let reportDataArr = data;
                        if (reportDataArr && reportDataArr.length) {
                            let headerTitle={};
                            if(activeTab === 'incentive_payment'){
                                headerTitle['SFA_USER']= t("INCENTIVE_PAYMENT.DOWNLOAD_CSV_TITLE.SFA_USER")
                                headerTitle['AP_REFERENCE_ID']= t("INCENTIVE_PAYMENT.DOWNLOAD_CSV_TITLE.AP_REFERENCE_ID")
                                headerTitle['USER_NAME']= t("INCENTIVE_PAYMENT.DOWNLOAD_CSV_TITLE.USER_NAME")
                                headerTitle['USER_ROLE']= t("INCENTIVE_PAYMENT.DOWNLOAD_CSV_TITLE.USER_ROLE")
                                headerTitle['NPWP_NUMBER']= t("INCENTIVE_PAYMENT.DOWNLOAD_CSV_TITLE.NPWP_NUMBER")
                                headerTitle['ACCOUNT_TO_BE_PAID'] = t("INCENTIVE_PAYMENT.DOWNLOAD_CSV_TITLE.ACCOUNT_TO_BE_PAID")
                                headerTitle['BANK_NAME']= t("INCENTIVE_PAYMENT.DOWNLOAD_CSV_TITLE.BANK_NAME")
                                headerTitle['BRANCH_NAME']=t("INCENTIVE_PAYMENT.DOWNLOAD_CSV_TITLE.BRANCH_NAME")
                                headerTitle['ACCOUNT_NUMBER']= t("INCENTIVE_PAYMENT.DOWNLOAD_CSV_TITLE.ACCOUNT_NUMBER")
                                headerTitle['BENEFICIARY_NAME']= t("INCENTIVE_PAYMENT.DOWNLOAD_CSV_TITLE.BENEFICIARY_NAME")
                                headerTitle['INCENTIVE_TYPE']= t("INCENTIVE_PAYMENT.DOWNLOAD_CSV_TITLE.INCENTIVE_TYPE")
                                headerTitle['INCENTIVE_PERIOD']= t("INCENTIVE_PAYMENT.DOWNLOAD_CSV_TITLE.INCENTIVE_PERIOD")
                                if(maintab === 'paid'){
                                headerTitle['PAYMENT_PAID_DATE'] = t("INCENTIVE_PAYMENT.DOWNLOAD_CSV.PAYMENT_PAID_DATE")
                                headerTitle['PAYMENT_UPDATED_DATE'] = t("INCENTIVE_PAYMENT.DOWNLOAD_CSV.PAYMENT_UPDATED_DATE")
                                headerTitle['OTO_BANK_NAME']= t("INCENTIVE_PAYMENT.DOWNLOAD_CSV.OTO_BANK")
                                headerTitle['UTR_NUMBER']= t("INCENTIVE_PAYMENT.DOWNLOAD_CSV.UTR_NUMBER")
                                }
                                if(maintab === 'pending'){
                                headerTitle['VERIFIER']= t("INCENTIVE_PAYMENT.DOWNLOAD_CSV_TITLE.VERIFIER")
                                headerTitle['MAKER']= t("INCENTIVE_PAYMENT.DOWNLOAD_CSV_TITLE.MAKER")
                                headerTitle['APPROVER']= t("INCENTIVE_PAYMENT.DOWNLOAD_CSV_TITLE.APPROVER")
                                }
                                headerTitle['STATUS']= t("INCENTIVE_PAYMENT.DOWNLOAD_CSV_TITLE.STATUS")
                                headerTitle['FINCARE_STATUS']= t("INCENTIVE_PAYMENT.DOWNLOAD_CSV_TITLE.FINCARE_STATUS")
                                headerTitle['INVOICE_DOCUMENT']= t("INCENTIVE_PAYMENT.DOWNLOAD_CSV_TITLE.INVOICE_DOCUMENT")
                                headerTitle['APPROVAL_DOCUMENT']= t("INCENTIVE_PAYMENT.DOWNLOAD_CSV_TITLE.APPROVAL_DOCUMENT")
                                headerTitle['AGREEMENT_DOC']= t("INCENTIVE_PAYMENT.DOWNLOAD_CSV_TITLE.AGREEMENT_DOC")
                            }
                            // let totalData = reportDataArr.length;
                            let csvReports = [];
                            for (let element of reportDataArr) {
                                let csvRecord = {};
                                if(activeTab === 'incentive_payment'){
                                    csvRecord[headerTitle.SFA_USER] = element.sfa_user_id;
                                    csvRecord[headerTitle.AP_REFERENCE_ID] = element.ap_reference_id;
                                    csvRecord[headerTitle.USER_NAME] = element.sfa_user_name;
                                    csvRecord[headerTitle.USER_ROLE] = element.sfa_user_role;
                                    csvRecord[headerTitle.NPWP_NUMBER] = element.npwp_number;
                                    csvRecord[headerTitle.ACCOUNT_TO_BE_PAID] = element && element.payment_amount ? numberFormatWithLocale(element.payment_amount, amountFormatType) : 0;
                                    csvRecord[headerTitle.BANK_NAME] = element.bank_name;
                                    csvRecord[headerTitle.BRANCH_NAME] = element.branch_name;
                                    csvRecord[headerTitle.ACCOUNT_NUMBER] = `="${element.account_number}"`;
                                    csvRecord[headerTitle.BENEFICIARY_NAME] = element.beneficiary_name;
                                    csvRecord[headerTitle.INCENTIVE_TYPE] = INCENTIVE_TYPE.filter(f=>f.value===+element.incentive_type).map(e=>e.label)[0] ;
                                    csvRecord[headerTitle.INCENTIVE_PERIOD] = element.incentive_period;
                                    if(maintab === 'paid'){
                                    csvRecord[headerTitle.PAYMENT_PAID_DATE] =  element.payment_date ? dateFormat(element.payment_date, DATE_FORMAT_YMD):'';
                                    csvRecord[headerTitle.PAYMENT_UPDATED_DATE] = element.updated_date ? dateFormat(element.updated_date, DATE_FORMAT_YMD):'';
                                    csvRecord[headerTitle.OTO_BANK_NAME] = ((BANK_LIST_OBJECTS[element.oto_bank] && BANK_LIST_OBJECTS[element.oto_bank]['label']) || element.oto_bank) + ' - ' + ((BANK_LIST_OBJECTS[element.oto_bank] && BANK_LIST_OBJECTS[element.oto_bank]['acc_number']) || element.oto_bank);
                                    csvRecord[headerTitle.UTR_NUMBER] = element.utr_number;
                                    }
                                    if(maintab === 'pending'){
                                    csvRecord[headerTitle.VERIFIER] = element.verifier_name;
                                    csvRecord[headerTitle.MAKER] = element.maker_name;
                                    csvRecord[headerTitle.APPROVER] = element.approver_name;
                                    }
                                    csvRecord[headerTitle.STATUS] = INVOICE_STATUS.filter(data => +data.value === +element.invoice_status).map(v => v.label)[0] || "";
                                    csvRecord[headerTitle.FINCARE_STATUS] = FINCARE_STATUS.filter(data => +data.value === +element.fincare_status).map(v => v.label + '||' + v.subStatus)[0] || "";
                                    csvRecord[headerTitle.INVOICE_DOCUMENT] = element.invoice_url ? element.invoice_url : '';
                                    csvRecord[headerTitle.APPROVAL_DOCUMENT] = element.approval_document ? element.approval_document : '';
                                    csvRecord[headerTitle.AGREEMENT_DOC] = element.agreement_doc ? element.agreement_doc : ''   ;

                                }
                                csvReports.push(csvRecord);
                            }
                            SingleExcelExport(csvReports, 'Incentive Payment Record', 'Incentive Payment Record');
                            hideModal()
                        }
                        else {
                            toast.error('Record not found')
                        }
                        setDownloadEnable(false);
                    }
                })
        }
        else {
            setDownloadEnable(true);
        }
    }
    const hideModal = () => {
        setShowDownloadModal(false);
        setAmountFormatType()
    }
    const handleChange = (sname, ovalue) => {
        if (ovalue && ovalue.value) {
            setAmountFormatType(
                ovalue.value
            );
        }
        errors[sname] = ''

        setError({ ...errors })    
    }
    const hideBulkModal = () => {
        setBulkUploadModal(false);
    }
     const showModalImpportdatapopup = () => {
        setBulkUploadModal(true)
    }

    const resetFileUpload = () => {
    }
    const syncBankInfo = async (reqBody) => {
        let isConfirm = window.confirm("Are you sure want to update?");
        if(!isConfirm) return false;
        setIsLoading(true);
        PaymentService.syncBankInfo(reqBody).then((response) => {
            if (response.status === 200 && response.data.status === 200) {
                toast.success(response.data.message);
                let responseData = response.data.data || {};
                if(reportData.length){

                    let reportData2upd = reportData.map(v=>{
                        if(+v.incentive_id === +reqBody.user_incentive_id){
                            v.account_number=responseData.account_number ? responseData.account_number : v.account_number;
                            v.bank_name=responseData.bank_name ? responseData.bank_name : v.bank_name;
                            v.beneficiary_name=responseData.beneficiary_name ? responseData.beneficiary_name : v.beneficiary_name;
                            v.branch_name=responseData.branch_name;
                        }
                        return v;
                    })
                    setReportData([...reportData2upd]);
                }
                // override the bank detail 
            }
            setIsLoading(false);
        });
    }

    const sendInvoiceToFincare = async (updateData=[],type) => {
        let postData = {
            user_id:loggedInUser.user_data.user_id || 0,
            action_type:type
        }
        if(updateData.length){
            postData.incentive_ids = (type === 'auto') ? 
            updateData.filter(f=>f.send_to_fincare_attempt < 5).map(e=>e.incentive_id)
            :
            updateData.filter(f=>f.send_to_fincare_attempt > 4).map(e=>e.incentive_id)
        }
        if(postData.incentive_ids && postData.incentive_ids.length){
            setIsLoading(true);
            await PaymentService.sendInvoiceToFincare(postData).then((response) => {
                if (response && response.status === 200) {
                     setInitialPage({ "page": 1, "payment_status": maintab, payment_source: activeTab });
                     getReport({ ...filterData, ...{ "page": 1, "payment_status": maintab, payment_source: activeTab } });
            		 toast.success(response.data.message);
                     setSelectedData([])
                }
                setIsLoading(false);
            });
        }else{
            toast.error('There is no valid data to send to Fincare')
        }
    }

    const handleChangeChkBox = (event,incentiveData,type) => {
        let tempSelectedData = [...selectedData];
        if(event.target.checked) {
            if((type === 'single')) tempSelectedData.push(incentiveData)
            else tempSelectedData = [...sendToFincareData]
        }else{
            if((type === 'single')){
                let index2Remove = tempSelectedData.findIndex(current_obj => current_obj.incentive_id === incentiveData.incentive_id);
                if(index2Remove >= 0)
                    tempSelectedData.splice(index2Remove,1)
            }else tempSelectedData = []
            
        }
        setShowConfirmationPopup(false)
        setSelectedData([...tempSelectedData])
    }

    const send2FincareMultipleData = (type) => {
        if(type === 'auto') {
            let isConfirm = window.confirm("Are you sure want to send selected invoice to fincare?");
            if(!isConfirm) return false;
            sendInvoiceToFincare(selectedData,type)
        }else{
            setData2SendManually([...selectedData]);
            setShowConfirmationPopup(true)
        }
       
    }
    
    const updateStateForManuallySendData = (data,type=false) => {
        if(type){
            setShowConfirmationPopup(false);
        } else {
            setData2SendManually([...data])
        }
    }
    
    let isNotDisabledExportFinance = reportData && reportData.length;
    //let isNotDisabledExportFinance = reportData && reportData.length && (exportFilterData && exportFilterData['paid'] && exportFilterData['paid'][activeTab] && exportFilterData['paid'][activeTab].date_filter_type && exportFilterData['paid'][activeTab].from_date && exportFilterData['paid'][activeTab].to_date);
    return (
        <div className="finance-report-outer">

                <div className="white-bg">
                    <div className="container">
                        {/* <div class="top-heading">
                            <h1>Incentive Payments</h1>
                        </div> */}
                        <div class="top-heading">
                        {/* eslint-disable-next-line jsx-a11y/heading-has-content */}
                        <h1></h1>
                            <div className='right-btn'>
                            {maintab === "pending" && loginUserData.includes('2') ? (
                                <button class="btn-line" onClick={() => showModalImpportdatapopup(true)} > Bulk Upload</button>
                                ) : null
                            }
                                </div>
                        </div>
                    </div>
                </div>
        <Tab.Container id="left-tabs-example" defaultActiveKey="pending">
            <Nav variant="pills" className="flex-column tab-bg">
                    
            </Nav>
            
                {/* <Tab.Pane active={activeTab} eventKey='incentive_payment'> */}
                    {showFilter ?
                        <div className="report-filter-outer">
                            <div className='filter-heading'>
                                <span className="sub-heading">{t("INCENTIVE_PAYMENT.SEARCH.FILTER_BY")} - {t("INCENTIVE_PAYMENT.SEARCH_RESULT")} : {searchResultCount || 0}</span>
                                {((maintab === "paid" || maintab === "pending") && ["incentive_payment"].includes(activeTab)) ? (
                                    <button
                                    className="btn-line btn-with-loader"
                                    disabled={
                                        (["incentive_payment"].includes(activeTab) && !isNotDisabledExportFinance) ||
                                        isFilter ||
                                        downloadEnable
                                    }
                                    onClick={() => setShowDownloadModal(true)}
                                    >
                                    <i className="ic-export"></i> <span>{t("INCENTIVE_PAYMENT.EXPORT_DATA")}</span>{" "}
                                    {downloadEnable ? (
                                        <div className="loader loader-default is-active loader-main"></div>
                                    ) : null}
                                    </button>
                                ) : null
                                }
                            </div>
                            <IncentiveSearch 
                                changeHandler={changeHandler}
                                filterData={filterData}
                                submiFilter={submiFilter}
                                resetFilter={resetFilter}
                                activeTab={maintab}
                                activeSubTab={activeTab}
                                isLoading={isFilter}
                                errorData={errors}
                                INVOICE_STATUS={INVOICE_STATUS}
                            />
                            
                            
                            <div className="selected-chips">
                                <ul>
                                    {(filtersArr[maintab] && filtersArr[maintab][activeTab] && filtersArr[maintab][activeTab].length > 0) ?
                                        filtersArr[maintab][activeTab].map((item, index) => {
                                            return (
                                                <li key={index}>
                                                    {item.labeltxt}-{item.label}
                                                    <span onClick={() => removeFilter(item.value, item.key)}>
                                                        <i className="ic-clearclose"></i>
                                                    </span>
                                                </li>
                                            )
                                        }) : null
                                    }
                                </ul>
                            </div>
                        </div> : null}
                    <Nav variant="pills" className="flex-column tab-bg tab-btn">
                        <div className="tab-list">
                            <Nav.Item>
                                <Nav.Link active={activeUserTab === 'my_cases'} eventKey="my_cases" onClick={() => tabChangesUserwise('my_cases')} >My Cases({totalTabCounts.my_cases || 0})</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link active={activeUserTab === 'all'} eventKey="all" onClick={() => tabChangesUserwise('all')}>All Cases({totalTabCounts.all_cases || 0})</Nav.Link>
                            </Nav.Item> 
                        </div>
                        {loginUserData.includes('4') && maintab === "pending" ? 
                        <div className='right-btn'>
                        
                        <button class="btn-line m-sm-r" onClick={() => send2FincareMultipleData('auto')} disabled={!selectedData.length}>
                            <span>Send To Fincare</span>
                        </button>
                        
                        <button class="btn-line" onClick={() => send2FincareMultipleData('manual')} disabled={!selectedData.length}>
                            <span>Create</span>
                        </button>
                       
                        </div>
                         : null }
                        
                    </Nav>
                        <IncentiveTable 
                            loading={isLoading}
                            loadMoreItems={loadMoreItems}
                            hasMoreItems={hasMoreItems}
                            reportData={reportData}
                            activeTab={maintab}
                            activeSubTab={activeTab}
                            getReport={getReport}
                            paymentUpdated={paymentUpdated}
                            payment_source={initialPage.payment_source}
                            isFilter={isFilter}
                            users = {users}
                            activeUserTab = {activeUserTab}
                            verifyIncentivePayment={verifyIncentivePayment}
                            updateAssignedUser={updateAssignedUser}
                            syncBankInfo={syncBankInfo}
                            sendInvoiceToFincare={sendInvoiceToFincare}
                            INVOICE_STATUS={INVOICE_STATUS}
                            FINCARE_STATUS={FINCARE_STATUS}
                            sendToFincareData={sendToFincareData}
                            selectedData={selectedData}
                            handleChangeChkBox={handleChangeChkBox}
                            data2SendManually={data2SendManually}
                            updateStateForManuallySendData={updateStateForManuallySendData}
                            showConfirmationPopup={showConfirmationPopup}
                        />
                        
                {/* </Tab.Pane> */}




                <div className="update-pyament-detail-form export-modal-popup">
                    <Modal
                        show={showDownloadModal}
                        handleClose={hideModal}
                    >
                        <div className="modal-header">
                            <h2>
                                {t("INCENTIVE_PAYMENT.DOWNLOAD_CSV.AMOUNT_FORMAT_TYPE")}
                            </h2>
                        </div>
                        <div className="modal-body">
                            <div className="update-detail-form">
                                <fieldset className={errors.amount_format_type ? "required-msg detail-form-select-bx" : "detail-form-select-bx"}>
                                    <div className="material">
                                        <Select
                                            id="amount_format_type"
                                            onChange={handleChange.bind(this, "amount_format_type")}
                                            options={AMOUNT_FORMAT_TYPE}
                                            name="amount_format_type"
                                            placeholder={t('INCENTIVE_PAYMENT.DOWNLOAD_CSV.AMOUNT_FORMAT_TYPE')}
                                            value={
                                                amountFormatType
                                                    ? AMOUNT_FORMAT_TYPE.filter(
                                                        ({ value }) =>
                                                            value === amountFormatType
                                                    )
                                                    : ""
                                            }
                                        />
                                        <label
                                            data-label={t('INCENTIVE_PAYMENT.DOWNLOAD_CSV.AMOUNT_FORMAT_TYPE')}
                                            className="form-label"
                                        ></label>

                                    </div>
                                </fieldset>


                                <div className="update-btn">
                                    <button
                                        className="btn-primary btn-with-loader"
                                        onClick={getCsvReportData}
                                        disabled={downloadEnable}
                                    > {t('INCENTIVE_PAYMENT.DOWNLOAD_CSV.DOWNLOAD')}
                                    {downloadEnable ? <div className="loader loader-default is-active loader-main"></div> : null}
                                    </button>
                                </div>
                            </div>
                        </div>

                    </Modal>
                </div>

                <div className="import-salary-data-popup model-popup-outer">
                    <Modal show={showBulkUploadModal} handleClose={hideBulkModal} >
                        <div className="modal-header">
                            <h2>Import Incentive Data</h2>
                        </div>
                            <FileUploader
                                 resetFileUpload={resetFileUpload}
                            />       
                    </Modal>
                </div>

            
        </Tab.Container>
        </div>
    )
}
export default IncentivePayment;
                                   