import React, { useState, useRef, useEffect } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import PaymentService from "../../service/paymentService";
import secureStorage from "./../../config/encrypt";
import { useSelector, useDispatch } from "react-redux";
import { userDetail } from "../../store/allAction";
import { hasAccessPermission } from "../../config/constant";
import Captcha from "../common/Captcha";
// import { IS_REDIS } from "../../config/constant";

function EmailUser() {

	const captchaData = useSelector((state) => { 
		return state.user;
	})
	const [reloadCaptcha, setReloadCaptcha] = useState(1);
	const dispatch = useDispatch();
	const btnRefs = useRef()
	
	const time = 59;
	const [counter, setCounter] = React.useState(time);
	const [otpFlag, setFlag] = useState(true);
	const [resendOtp, setResend] = useState(false);

	const [inputField, setInputField] = useState( captchaData.is_radis === true ? {
		email: "",
		password: "",
		captcha:"",
		otp:"",
	} : {
		email: "",
		password: "",
		otp:"",
	});
	const [errField, setErrField] = useState({
		emailErr: "",
		passwordErr: "",
		captchaErr:"",
		otpErr:""
	});
	const history = useNavigate();
	const validForm = () => {
		let formIsValid = true;
		setErrField({
			emailErr: "",
			passwordErr: "",
			captchaErr:"",
			otpErr:""
		});
		if (inputField.email === "") {
			formIsValid = false;
			setErrField((prevState) => ({
				...prevState,
				emailErr: "Please Enter Email",
			}));
		}
		if (inputField.password === "") {
			formIsValid = false;
			setErrField((prevState) => ({
				...prevState,
				passwordErr: "Please Enter password",
			}));
		}

		else{
			formIsValid = true;

		}

		return formIsValid;
	};
	const inputHandler = (e) => {
		//btnRefs.current.removeAttribute("disabled");
		if (e.target.name === 'email') {
			setErrField((prevState) => ({
				...prevState,
				emailErr: "",
			}));
		}
		if (e.target.name === 'password') {
			setErrField((prevState) => ({
				...prevState,
				passwordErr: "",
			}));
		}
		setInputField({ ...inputField, [e.target.name]: e.target.value });
	};
	const focusEmail = useRef(); 
	const focusPwd = useRef(); 

	/* eslint-disable react-hooks/exhaustive-deps */
	useEffect(() => {
		const loggedInUser = secureStorage.getItem("authUserInfo");
		if (loggedInUser && loggedInUser.user_data) {
			let url2redirct = (hasAccessPermission('salary-report')) ? `/salary-report` : '/incentive/pending/incentive_payment';
			history(url2redirct)
		} else {
			if (focusEmail.current) {
				setTimeout(() => {
					focusEmail.current.focus();
				}, 500)
				setTimeout(() => {
					focusPwd.current.focus();
				}, 400)
			}
		}

		if (counter === 0) {
			setResend(true);
		}

	}, [focusEmail, counter === 0]);

	const submitButton = async () => {
		//btnRefs.current.setAttribute("disabled", "disabled"); 
		if (validForm()) {
			let response = await PaymentService.userLogin(inputField); 
			if (response && response.status === 200) {
				setReloadCaptcha((prev) => prev + 1)

				let { permissions } = response.data.data;
				let userInfo = response.data.data;
				secureStorage.setItem("authUserInfo", userInfo);
				secureStorage.setItem("userAccess", permissions);
				dispatch(userDetail(userInfo.user_data));
				toast.success(`Login Successfully`);
				//let authUser = userInfo.user_data;
				let url2redirct = (hasAccessPermission('salary-report')) ? `/salary-report` : '/incentive/pending/incentive_payment'
				history(url2redirct)
				//setTimeout(() => history.push(url2redirct), 1000); 
			}else{
				setReloadCaptcha((prev) => prev + 1)
				//btnRefs.current.removeAttribute("disabled");
			}
		}
	};
	const handleKeyPress = (e) => {
		if (e.charCode === 13) submitButton();
		return true
	}

	const captchaFiledHandler = (childData) => {
		//setFields(childData);
		setInputField(childData);
		if (captchaData.captchaValue) {
			setInputField({ ...inputField, captcha: childData, hash: captchaData.captchaValue })
		}
	}

	const otpSend = async () => {
		if (validForm("mobile")) {
			if (btnRefs.current) btnRefs.current.setAttribute("disabled", "disabled");
			let response = await PaymentService.userOtpSend({
				email: inputField.email,
				password: inputField.password
			});
			if (btnRefs.current) btnRefs.current.removeAttribute("disabled");
			if (response && response.status === 200) {
				setFlag(false);
				setResend(false);
				setCounter(time);
				toast.success(`OTP Sent Successfully`);
				var interval = setInterval(() => {
					setCounter((time) =>
						time > 0 ? time - 1 : clearInterval(interval)
					);
				}, 1000);
			}
			setReloadCaptcha((prev) => prev + 1)
		}
	};

	return (
		<div className="login-form">
			<form autoComplete="on">
			{otpFlag ? (
			    <React.Fragment>
				<fieldset className="">
					<div
						className={
							"material formfield " +
							(errField.emailErr.length > 0 ? "error" : "")
						}
					>
						<input
							type="text"
							placeholder=" "
							name="email"
							value={inputField.email}
							className="form-input"
							onChange={inputHandler}
							ref={focusEmail}
						/>
						<i className="ic-email1 icn-login"></i>
						<label
							data-label="Email"
							className="form-label"
						></label>
						{errField.emailErr.length > 0 && (
							<span className="erroe-txt">
								{errField.emailErr}
							</span>
						)}
					</div>
				</fieldset>
				<fieldset className="">
					<div
						className={
							"material " +
							(errField.passwordErr.length > 0 ? "error" : "")
						}
					>
						<input
							type="password"
							placeholder=" "
							name="password"
							value={inputField.password}
							className="form-input"
							onChange={inputHandler}
							onKeyPress={handleKeyPress}
							ref={focusPwd}
						/>
						<i className="ic-password icn-login"></i>
						<label
							data-label="Password"
							className="form-label"
						></label>
						{errField.passwordErr.length > 0 && (
							<span className="erroe-txt">
								{errField.passwordErr}
							</span>
						)}
					</div>
				</fieldset>
				{/* {IS_REDIS ? 
				<fieldset className="">
				<Captcha onKeyPressCaptcha={ captchaFiledHandler } errField={errField} inputField={inputField} reloadCaptcha={reloadCaptcha}/>
				</fieldset>
				: null } */}
				<button type="button" className="btn-primary"
							onClick={otpSend}
							ref={btnRefs}
						>
							Login
						</button>
				</React.Fragment>
				) : (
						<React.Fragment>
							<fieldset className="">
								<div className="material">
									<input
										className="form-input"
										type="text"
										placeholder=" "
										name="otp"
										value={inputField.otp}
										onChange={inputHandler}
									/>
									<i className="ic-otp icn-login">
										<i className="path1"></i>
										<i className="path2"></i>
										<i className="path3"></i>
										<i className="path4"></i>
									</i>
									<label
										data-label="OTP"
										className="form-label"
									></label>
									{resendOtp ? null : (
										<span className="otp-time">
											00:{counter}
										</span>
									)}
									<div
										className="otp-success-icon"
										style={{ display: "none" }}
									>
										<i className="ic-check"></i>
										{errField.otpErr.length > 0 && (
											<span className="erroe-txt">
												{errField.otpErr}
											</span>
										)}
									</div>
								</div>
							</fieldset>

							{captchaData.is_radis === false ?
							<fieldset className=""  style={{ display: "none"}}>
								<Captcha onKeyPressCaptcha={ captchaFiledHandler } errField={errField} inputField={inputField} reloadCaptcha={reloadCaptcha}/>
							</fieldset> : 
							<fieldset className="" >
							<Captcha onKeyPressCaptcha={ captchaFiledHandler } errField={errField} inputField={inputField} reloadCaptcha={reloadCaptcha}/>
							</fieldset>
							}
							{resendOtp === true ? (
								<div className="btn-submit-resend">
									<button
										type="button"
										className="btn-primary"
										onClick={submitButton}
									>
										Submit
								</button>
									<button
										type="button"
										className="btn-line"
										onClick={otpSend}
									>
										Resend
								</button>
								</div>
							) : (
									<button
										type="button"
										className="btn-primary"
										onClick={submitButton}
									>
										Submit
							</button>
								)}
						</React.Fragment>
					)}
			</form>
		</div>
	);
}

export default EmailUser;
