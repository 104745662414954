import React, { useEffect, useState } from 'react';
import CommonService from "../../service/commonService";
import Select, { components } from 'react-select';
import { useTranslation } from "react-i18next";
import noImage from "../../webroot/images/noimage.svg";
import pdfIcon from "../../webroot/images/pdf.svg";
import { FAM_ALLOWED_DOC_CATEGORY } from "../../config/constant";

const { ValueContainer, Placeholder } = components;

const CustomValueContainer = ({ children, ...props }) => {
    return (
        <ValueContainer {...props}>
            <Placeholder {...props} isFocused={props.isFocused}>
                {props.selectProps.placeholder}
            </Placeholder>
            {React.Children.map(children, child =>
                child && child.type !== Placeholder ? child : null
            )}
        </ValueContainer>
    );
};

const DocSlider = ({ incentiveDetails }) => {
    /*  Decleare state variable */
    const { t } = useTranslation("language");
    const [docCategoryList, setDocCategoryList] = useState([])
    const [documentList, setDocList] = useState([]);
    const [ , setCategoryList] = useState([]);
    const [subCategoryList, setSubCategoryList] = useState([]);
    const [, setIsDocLoading] = useState(false);
    const defaultCategory = (incentiveDetails.lead_source === 'finance') ? { label: "DO", value: 13 } : {label: "Applicant’s ID Card", value: 17};
    const defaultSubCategory = (incentiveDetails.lead_source === 'finance') ? { label: "PO calculator", value: 63 } : {label: "ID Card", value: 1 };
    const [docGallery, setDocGallery] = useState({
        selectedCategory: { ...defaultCategory },
        selectedSubCategory: { ...defaultSubCategory },
        filterFiles: [],
        filterCategory: docCategoryList
    });
    
    /*  This fn is used to call all required api on load on component */
    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        if (incentiveDetails && incentiveDetails.lead_id) {
            setIsDocLoading(true);
            getDocumentCategory();
            // getLeadDocs({ lead_id: incentiveDetails.lead_id })
        }
    }, [incentiveDetails,incentiveDetails.loan_id])

    /*  This fn is used get category list */
    const getDocumentCategory = async () => {
        CommonService.getDocumentList({loan_id: incentiveDetails.loan_id, lead_source: incentiveDetails.lead_source || ''})
            .then((response) => {
                if (response.status === 200 && response.data.status === 200) {
                    let docCategoryList = (response.data.data && response.data.data.category_list) || [];
                    let category = [], po_sub_cat = [];
                    setDocCategoryList(docCategoryList);
                    if (incentiveDetails.lead_source === 'finance') {
                        category = docCategoryList.filter(f => f.parent_id === 0 && FAM_ALLOWED_DOC_CATEGORY.includes(f.id)).map(e => ({ label: e.name, value: e.id }))
                        po_sub_cat = docCategoryList.filter(f => f.parent_id === 13).map(e => ({ label: e.name, value: e.id }));
                    } else {
                        category = docCategoryList.filter(f => f.parent_id === 0).map(e => ({ label: e.name, value: e.id }))
                        po_sub_cat = docCategoryList.filter(f => f.parent_id === 17).map(e => ({ label: e.name, value: e.id }));
                    }
                    setCategoryList(category);
                    // set subcategory
                    setSubCategoryList(po_sub_cat)

                    let leadDocs = (response.data.data && response.data.data.documents_list) || [];

                    setDocList(leadDocs);
                    /*  Set default image on load */
                    let filtredFiles = leadDocs.reduce((res, data) => {
                        if (data.doc_id === defaultSubCategory.value) res.push(data);
                        return res;
                    }, []);
                    setDocGallery({
                        ...docGallery, filterFiles: filtredFiles, selectedCategory: { ...defaultCategory },
                        selectedSubCategory: { ...defaultSubCategory }
                    });
                    setIsDocLoading(false);

                }
            })
            .catch(() => { });
    };
    /*  This fn is used get document list */
    // const getLeadDocs = async (postData) => {
    //     CommonService.getLeadDocs(postData)
    //         .then((response) => {
    //             if (response.status === 200 && response.data.status === 200) {
    //                 let leadDocs = response.data.data || {};
    //                 setDocList(leadDocs);
    //                 /*  Set default image on load */
    //                 let filtredFiles = leadDocs.reduce((res, data) => {
    //                     if (data.doc_id === defaultSubCategory.value) res.push(data);
    //                     return res;
    //                 }, []);
    //                 setDocGallery({
    //                     ...docGallery, filterFiles: filtredFiles, selectedCategory: { ...defaultCategory },
    //                     selectedSubCategory: { ...defaultSubCategory }
    //                 });
    //                 setIsDocLoading(false);
    //             }
    //         })
    //         .catch(() => { });
    // };

    /*  This function is used to handle onchnage event of category and sub category */
    const handleChange = (key, field_obj) => {
        let docList = documentList, filtredFiles = [];
        if (key === 'selectedCategory') {
            let subCategory = docCategoryList.reduce((res, data) => {
                if (data.parent_id === field_obj.value) res.push({ label: data.name, value: data.id });
                return res;
            }, []);
            /* If child category is not available then filter doc on parent category */
            if (!subCategory.length) {
                filtredFiles = docList.reduce((res, data) => {
                    if (data.doc_id === field_obj.value) res.push(data);
                    return res;
                }, []);
            }
            setSubCategoryList(subCategory)
            setDocGallery({ ...docGallery, selectedCategory: field_obj, selectedSubCategory: [], filterFiles: filtredFiles })
        } else {
            filtredFiles = docList.reduce((res, data) => {
                if (data.doc_id === field_obj.value) res.push(data);
                return res;
            }, []);
            setDocGallery({ ...docGallery, selectedSubCategory: field_obj, filterFiles: filtredFiles })
        }
    }
    return (
        <React.Fragment>
            <div className="modal-header" key={incentiveDetails.incentive_id}>
                <h2>{t("INCENTIVE_PAYMENT.REFERENCE_DOCUMENTS") + ': ' + t("INCENTIVE_PAYMENT.SFA_ID")} {incentiveDetails.sfa_user_id}</h2>
            </div>
            <div className="category-select-bx">
                <fieldset className="single-select">
                    <div className="material">
                        {/* <Select components={{ ValueContainer: CustomValueContainer }}
                            placeholder="Category"
                            className="react-select"
                            classNamePrefix="react-select"
                            options={categoryList}
                            name="selectedCategory"
                            value={docGallery.selectedCategory && categoryList ? categoryList.filter(({ value }) => value === docGallery.selectedCategory.value) : ""}
                            onChange={handleChange.bind(this, 'selectedCategory')}
                        /> */}
                    </div>
                </fieldset>
                {subCategoryList.length ? 
                <fieldset className="single-select">
                    <div className="material">
                        <Select components={{ ValueContainer: CustomValueContainer }}
                            options={subCategoryList}
                            placeholder="Sub-category"
                            className="react-select"
                            classNamePrefix="react-select"
                            name="selectedSubCategory"
                            value={docGallery.selectedSubCategory}
                            onChange={handleChange.bind(this, 'selectedSubCategory')}
                        />
                    </div>
                </fieldset> : null }
            </div>

            <div className="modal-body" >
                {/* {docGallery.filterFiles && docGallery.filterFiles.length ? */}
                {((incentiveDetails.invoice_url &&  incentiveDetails.invoice_url!=='') || (incentiveDetails.approval_document &&  incentiveDetails.approval_document !== '')) ?
                <div className="slider-outer">
                    {incentiveDetails.invoice_url &&  incentiveDetails.invoice_url !== '' ? 
                    <span className='customInvoice'>
                    <a href={incentiveDetails.invoice_url} target="_blank" rel="noopener noreferrer"><img src={pdfIcon} alt=''/></a>
                    <h4>{t("INCENTIVE_PAYMENT.INVOICE_DOCUMENT")}</h4> </span> : ''
                    }
                    {incentiveDetails.approval_document &&  incentiveDetails.approval_document !== '' ?
                    <span className='customInvoice'>
                    <a href={incentiveDetails.approval_document} target="_blank" rel="noopener noreferrer"><img src={pdfIcon} alt='' /></a>
                    <h4>{t("INCENTIVE_PAYMENT.APPROVAL_DOCUMENT")}</h4>
                    </span> : "" }
                    {incentiveDetails.agreement_doc &&  incentiveDetails.agreement_doc !== '' ?
                    <span className='customInvoice'>
                    <a href={incentiveDetails.agreement_doc} target="_blank" rel="noopener noreferrer"><img src={pdfIcon} alt='' /></a>
                    <h4>{t("INCENTIVE_PAYMENT.AGREEMENT_DOC")}</h4>
                    </span> : "" }
                </div> :
                <div className="verfication-img-no-image">
                    <img src={noImage} alt='' className="" />
                    <span className="no-img-txt">No Document Attached</span>
                </div>
                }

            </div>
        </React.Fragment>


    );
}

export default DocSlider;