import React from "react";
import { Tab, Nav } from "react-bootstrap";
// import logo_login from "../../webroot/images/login_logo.svg";
import EmailUser from "./EmailUser";
import MobileUser from "./MobileUser";
import { useTranslation } from "react-i18next";

function Login() {
	const { t } = useTranslation("language");
	return (
		<div>
			<div className="container-fluid">
				<div className="login-outer">
					<div className="login-left-panel">
						<div className="top-logo">
							{/* <img src={logo_login} className="" /> */}
						</div>
						<div className="heading-txt">
							<span>{t("LOGIN.WELCOME_TO")}</span>
							<h1 style={{color: "red"}}>{t("LOGIN.SITE_TITLE")}</h1>
						</div>
					</div>
					<div className="login-right-panel">
						<h2>{t("LOGIN.LOGIN")}</h2>
						<Tab.Container id="" defaultActiveKey="first">
							<div className="login-tab">
								<Nav variant="pills" className="flex-column">
									<Nav.Item>
										<Nav.Link eventKey="first">
										{t("LOGIN.BY_EMAIL")}
										</Nav.Link>
									</Nav.Item>
									<Nav.Item>
										<Nav.Link eventKey="second">
										{t("LOGIN.BY_MOBILE")}
										</Nav.Link>
									</Nav.Item>
								</Nav>

								<Tab.Content>
									<Tab.Pane eventKey="first">
										<EmailUser />
									</Tab.Pane>
									<Tab.Pane eventKey="second">
										<MobileUser />
									</Tab.Pane>
								</Tab.Content>
							</div>
						</Tab.Container>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Login;
