import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { INCENTIVE_TYPE, SFA_USER_ROLE, DATE_FORMAT_INPUT } from '../../config/constant'
import DatePicker from "../elements/DatePicker";
import { useTranslation } from "react-i18next";
import { Dropdown } from 'react-bootstrap';
import { InputOption, MultiSelectValueContainer } from '../elements/MultiselectCustomComponent';

//const { ValueContainer, Placeholder } = components;

const DateType = [
    { value: 'payment_paid', label: 'Payment Paid Date' },
    { value: 'payment_updated', label: 'Payment Updated Date' },
];


const IncentiveSearch = (props) => {
    const { t } = useTranslation("language");
    const [filterData, setFilterData] = useState({ ...props.filterData[props.activeTab] })
    useEffect(() => {
        setFilterData({ ...props.filterData[props.activeTab] })
    }, [props])
    const getDropdownButtonLabel = ({ placeholderButtonLabel, value }) => {
        if (value && value.length === 1) {
            return `${value[0].name || value[0].label}`;
        } else if (value && value.length > 1) {
            return `${value.length} selected`;
        }
        else
            return `${placeholderButtonLabel}`;

    }
    const {errorData,INVOICE_STATUS} = props;

    //console.log('errorData==========',errorData,'filterData==',filterData)
    return (
        <div className={props.activeTab === 'pending' ? "report-filter-filed pending-incentive-payment-report outbond-selcted-filter" : "report-filter-filed outbond-selcted-filter outbond-incentive-paid-filter"}>

            <fieldset className="form-filed searchbox">
                <div className="material">
                    <input id="" type="text" placeholder=" " name="sfa_user" className="form-input" onChange={(v) => props.changeHandler('sfa_user', v.target.value)} value={(filterData && filterData[props.activeSubTab] && filterData[props.activeSubTab].sfa_user) || ""}
                    autoComplete="off"/>
                    <label data-label={t("INCENTIVE_PAYMENT.AGENT_NAME_SFA_ID")} className="form-label"></label>
                    <i className='ic-search'></i>
                </div>
            </fieldset>
            {props.activeTab === 'pending' ?
                <fieldset className="single-select">
                    <div className="material">
                        <Select components={{ Option: InputOption, ValueContainer: MultiSelectValueContainer }}
                            isMulti
                            className="react-select react-multiselect-opt"
                            classNamePrefix="react-select"
                            id="user_role"
                            name="user_role"
                            options={SFA_USER_ROLE}
                            placeholderButtonLabel={t("INCENTIVE_PAYMENT.USER_ROLE")}
                            placeholder={t("INCENTIVE_PAYMENT.USER_ROLE")}
                            closeMenuOnSelect={false}
							hideSelectedOptions={false}
							backspaceRemovesValue={false}
							isClearable={false}
                            iconAfter="false"
                            getDropdownButtonLabel={getDropdownButtonLabel}
                            onChange={(v) => props.changeHandler('user_role', v)}
                            value={
                                (SFA_USER_ROLE instanceof Array &&
                                    filterData && filterData[props.activeSubTab] && filterData[props.activeSubTab].user_role &&
                                    SFA_USER_ROLE.filter((e) =>
                                        [
                                            ...(filterData[props.activeSubTab].user_role instanceof
                                                Array
                                                ? filterData[props.activeSubTab].user_role
                                                : []),
                                        ].includes(e.value)
                                    )) ||
                                []
                            }
                            isSearchable="true"
                        />
                    </div>
                </fieldset>
                : null}
            <fieldset className="single-select">
                <div className="material">
                    <Select
                        id="incentive_type"
                        name="incentive_type"
                        onChange={(v) => props.changeHandler('incentive_type', v.value)}
                        options={INCENTIVE_TYPE}
                        placeholder={t("INCENTIVE_PAYMENT.INCENTIVE_TYPE")}
                        className="react-select"
                        classNamePrefix="react-select"
                        value={filterData && filterData[props.activeSubTab] && filterData[props.activeSubTab].incentive_type ? INCENTIVE_TYPE.filter(({ value }) => value === filterData[props.activeSubTab].incentive_type) : ""}
                    />
                </div>
            </fieldset>
            {props.activeTab === 'pending' ?
            <fieldset className="single-select">
                <div className="material">
                    <Select
                        id="invoice_status"
                        name="invoice_status"
                        onChange={(v) => props.changeHandler('invoice_status', v.value)}
                        options={INVOICE_STATUS}
                        placeholder={t("INCENTIVE_PAYMENT.INVOICE_STATUS")}
                        className="react-select"
                        classNamePrefix="react-select"
                        value={filterData && filterData[props.activeSubTab] && filterData[props.activeSubTab].invoice_status ? INVOICE_STATUS.filter(({ value }) => value === filterData[props.activeSubTab].invoice_status) : ""}
                    />
                </div>
            </fieldset>:null}


            {props.activeTab === 'paid' ? (
                <React.Fragment>
                    <fieldset className={"single-select"+(errorData.date_filter_type ? ' required-msg' : '')}>
                        <div className="material">
                            <Select 
                                id="date_filter_type"
                                name="date_filter_type"
                                options={DateType}
                                placeholder={t("INCENTIVE_PAYMENT.SEARCH.DATE_TYPE")}
                                className="react-select"
                                classNamePrefix="react-select"
                                onChange={(v) => props.changeHandler('date_filter_type', v.value)}
                                value={filterData && filterData[props.activeSubTab] && filterData[props.activeSubTab].date_filter_type ? DateType.filter(({ value }) => value === filterData[props.activeSubTab].date_filter_type) : ""}
                            />
                            
                        </div>
                    </fieldset>
                    <fieldset className={"single-select"+(errorData.from_date ? ' required-msg' : '')}>
                        <div className="material">
                            <DatePicker
                                id="from_date"
                                name="from_date"
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                autoComplete="off"
                                placeholderText={t("INCENTIVE_PAYMENT.SEARCH.FROM_DATE")}
                                selected={filterData && filterData[props.activeSubTab] && filterData[props.activeSubTab].from_date ? new Date(filterData[props.activeSubTab].from_date) : ""}
                                onChange={(v) => props.changeHandler('from_date', v)}
                                maxDate={new Date()}
                                onKeyDown={(e) => e.preventDefault()}
                                dateFormat={DATE_FORMAT_INPUT}
                                disabled={filterData && filterData[props.activeSubTab] && filterData[props.activeSubTab].date_filter_type ? false : true}
                            />
                        </div>
                    </fieldset>
                    <fieldset className={"single-select"+(errorData.to_date ? ' required-msg' : '')}>
                        <div className="material">
                            <DatePicker
                                id="to_date"
                                name="to_date"
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                autoComplete="off"
                                placeholderText={t("INCENTIVE_PAYMENT.SEARCH.TO_DATE")}
                                selected={filterData && filterData[props.activeSubTab] && filterData[props.activeSubTab].to_date ? new Date(filterData[props.activeSubTab].to_date) : ""}
                                onChange={(v) => props.changeHandler('to_date', v)}
                                onKeyDown={(e) => e.preventDefault()}
                                dateFormat={DATE_FORMAT_INPUT}
                                maxDate={new Date()}
                                minDate={(filterData && filterData[props.activeSubTab] && filterData[props.activeSubTab].from_date ? new Date(filterData[props.activeSubTab].from_date) : "")}
                                //maxDate={maxDATE(filterData && filterData[props.activeSubTab] && filterData[props.activeSubTab].from_date ? new Date(filterData[props.activeSubTab].from_date) : "", 3)}
                                disabled={filterData && filterData[props.activeSubTab] && filterData[props.activeSubTab].from_date ? false : true}
                            />
                        </div>
                    </fieldset>


                    <div className="more-filter">
                        <React.Fragment>
                            <div className="filter-option more-filter-incentive-paid">
                                <Dropdown>
                                    <div className="text-btn d-flex">
                                        <Dropdown.Toggle variant="success" id="dropdown-basic">

                                            <span className="more-filter-txt">{t("INCENTIVE_PAYMENT.MORE_FILTERS")}</span>
                                            <span className="leass-filter-txt">{t("INCENTIVE_PAYMENT.LESS_FILTERS")}</span>
                                        </Dropdown.Toggle>

                                        <div className="btn-submit-reset top-btn-none">
                                            <button className="btn-primary" disabled={props.isLoading} onClick={(e) => props.submiFilter(e)}>{t("INCENTIVE_PAYMENT.SEARCH.FILTER_REPORT")}</button>
                                            <button className="btn-reset" onClick={() => props.resetFilter('reset')}>{t("INCENTIVE_PAYMENT.SEARCH.RESET")}</button>
                                        </div>
                                    </div>
                                    <Dropdown.Menu>
                                        <div className="more-filter-option">
                                            <h3>{t("INCENTIVE_PAYMENT.MORE_FILTERS")}</h3>
                                            <div className="filter-form">
                                                <fieldset className="single-select">
                                                    <div className="material">
                                                        <Select components={{ Option: InputOption, ValueContainer: MultiSelectValueContainer }}
                                                            isMulti
                                                            className="react-select react-multiselect-opt"
                                                            classNamePrefix="react-select"
                                                            id="user_role"
                                                            name="user_role"
                                                            options={SFA_USER_ROLE}
                                                            placeholderButtonLabel={t("INCENTIVE_PAYMENT.USER_ROLE")}
                                                            placeholder={t("INCENTIVE_PAYMENT.USER_ROLE")}
                                                            closeMenuOnSelect={false}
                                                            hideSelectedOptions={false}
                                                            backspaceRemovesValue={false}
                                                            isClearable={false}
                                                            iconAfter="false"
                                                            getDropdownButtonLabel={getDropdownButtonLabel}
                                                            onChange={(v) => props.changeHandler('user_role', v)}
                                                            value={
                                                                (SFA_USER_ROLE instanceof Array &&
                                                                    filterData && filterData[props.activeSubTab] && filterData[props.activeSubTab].user_role &&
                                                                    SFA_USER_ROLE.filter((e) =>
                                                                        [
                                                                            ...(filterData[props.activeSubTab].user_role instanceof
                                                                                Array
                                                                                ? filterData[props.activeSubTab].user_role
                                                                                : []),
                                                                        ].includes(e.value)
                                                                    )) ||
                                                                []
                                                            }
                                                            isSearchable="true"
                                                        />
                                                    </div>
                                                </fieldset>
                                                <fieldset className="multiselect-dropDown">
                                                    <div className="material">
                                                        <DatePicker
                                                            id="incentive_period"
                                                            name="incentive_period"
                                                            dateFormat="MM/yyyy"
                                                            showMonthYearPicker
                                                            dropdownMode="select"
                                                            autoComplete="off"
                                                            placeholderText={t("INCENTIVE_PAYMENT.INCENTIVE_PERIOD")}
                                                            selected={filterData && filterData[props.activeSubTab] && filterData[props.activeSubTab].incentive_period ? new Date(filterData[props.activeSubTab].incentive_period) : ""}
                                                            onChange={(v) => props.changeHandler('incentive_period', v)}
                                                            onKeyDown={(e) => e.preventDefault()}
                                                            //minDate={(filterData && filterData[props.activeSubTab] && filterData[props.activeSubTab].incentive_period ? new Date(filterData[props.activeSubTab].incentive_period) : "")}
                                                            maxDate={new Date()}
                                                        />
                                                    </div>
                                                </fieldset>

                                                <fieldset className="single-select">
                                                    <div className="material">
                                                        <Select
                                                            id="invoice_status"
                                                            name="invoice_status"
                                                            onChange={(v) => props.changeHandler('invoice_status', v.value)}
                                                            options={INVOICE_STATUS}
                                                            placeholder={t("INCENTIVE_PAYMENT.INVOICE_STATUS")}
                                                            className="react-select"
                                                            classNamePrefix="react-select"
                                                            value={filterData && filterData[props.activeSubTab] && filterData[props.activeSubTab].invoice_status ? INVOICE_STATUS.filter(({ value }) => value === filterData[props.activeSubTab].invoice_status) : ""}
                                                        />
                                                    </div>
                                                </fieldset>



                                                <div className="btn-submit-reset more-filter-btn">
                                                    <button className="btn-primary" disabled={props.isLoading} onClick={(e) => props.submiFilter(e)}>{t("INCENTIVE_PAYMENT.SEARCH.FILTER_REPORT")}</button>
                                                    <button className="btn-reset" onClick={() => props.resetFilter('reset')}>{t("INCENTIVE_PAYMENT.SEARCH.RESET")}</button>
                                                </div>

                                            </div>
                                        </div>

                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>

                        </React.Fragment>
                    </div>
                </React.Fragment>
            ) :
                <div className="btn-submit-reset filter-report-btn">
                    <button className="btn-primary" disabled={props.isLoading} onClick={(e) => props.submiFilter(e)}>{t("INCENTIVE_PAYMENT.SEARCH.FILTER_REPORT")}</button>
                    <button className="btn-reset" onClick={() => props.resetFilter('reset')}>{t("INCENTIVE_PAYMENT.SEARCH.RESET")}</button>
                </div>}

        </div>
    )
}
export default IncentiveSearch;